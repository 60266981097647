import { setThrift, MrkClientServiceClient } from 'api';
import { reduce } from 'lodash';
import { log, PUBLIC_URL, getLocaleCode } from 'utils/helpers';
import moment from 'moment';

const getSignType = str => {
  switch (str) {
    case 'kazSign':
      return SignProviderType.KAZ_SIGN;
    case 'uaSign':
      return SignProviderType.UA_SIGN;
    default:
      return str;
  }
};

const getInitialState = currentState => {
  return new Promise(async (resolve, reject) => {
    let store = currentState();
    let auth = { ...store.auth };
    try {
      const frontRequest = await fetch(`${PUBLIC_URL}/web-config.json`);
      const frontSettings = await frontRequest.json();
      await setThrift(frontSettings);
      if (auth.token !== null) {
        try {
          let authenticate = await MrkClientServiceClient.refreshMrkClientSession(
            auth.token
          );
          if (authenticate) auth = {
            ...authenticate,
            token: authenticate.id
          };
        } catch (error) {
          log(error);
          auth = {
            ...auth,
            token: null
          };
          localStorage.removeItem('token');
        }
      }
      const DEFAULT_TRANSLATE = localStorage.getItem('lang') || frontSettings.LANG;
      const translate = await fetch(
        `${PUBLIC_URL}/translates/${DEFAULT_TRANSLATE}.json`
      );
      let translations = {
        [DEFAULT_TRANSLATE]: await translate.json()
      };
      moment.locale(DEFAULT_TRANSLATE);
      moment.suppressDeprecationWarnings = true;
      const languages = await MrkClientServiceClient.getAllLanguages();
      const settings = await MrkClientServiceClient.getInfo();
      const antdLocales = await getLocaleCode(DEFAULT_TRANSLATE);
      translations = {
        [DEFAULT_TRANSLATE]: {
          antd: antdLocales.default,
          ...translations[DEFAULT_TRANSLATE]
        }
      };
      resolve({
        ...store,
        auth,
        settings: {
          ...settings,
          ...frontSettings,
          //SIGN_PROVIDER_TYPE: SignProviderType.KAZ_SIGN,
          SIGN_PROVIDER_TYPE: getSignType(settings.SIGN_PROVIDER)
        },
        i18n: {
          locale: DEFAULT_TRANSLATE,
          translations,
          list: reduce(
            languages,
            (array, item, key) => {
              array.push({
                name: item,
                value: key
              });
              return array;
            },
            []
          )
        }
      });
    } catch (error) {
      log(error);
      reject(error);
    }
  });
};

export { getInitialState };
