import React, { useEffect, useRef } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Tooltip, Col, Row, notification } from 'antd';
import { Formik, setNestedObjectValues } from 'formik';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-modals';
import { get, size, filter, map, isEmpty, uniqueId, sortBy } from 'lodash';
import ListDocumentPatterns from './components/ListDocumentPatterns';
import FormData from './components/FormData';
import { Modal } from 'components/Modals';
import { log, NotificationError } from 'utils/helpers';
import { initState, prepareDraftDocument, getAllDocumentPatterns, createOrUpdateMrkDocument } from 'redux/actions/Modal/createMrkDocument';
import Loader from 'components/Loader';
import { MrkClientServiceClient, subscribe, unsubscribe } from 'api';
import { signAttachmentIIT, connectNcaLayer, signAttachmentNcaLayer } from 'utils/sign';
import * as Yup from 'yup';
const socketId = uniqueId('socket_update_doc_');

const CreateMrkDocument = ({ hideModal,
  token,
  newMrkDocument,
  parentId,
  extRespPatternId,
  extRespReq,
  step,
  documentPattern,
  isPrepareFetching,
  isFetching,
  mrkDocumentData,
  prepareDraftDocument,
  getAllDocumentPatterns,
  createOrUpdateMrkDocument,
  initState,
  accountId,
  showModal,
  organization,
  SEND_DOC_WITH_ECP,
  SIGN_PROVIDER_TYPE,
  ALLOWED_ATTACHMENT_UPLOAD
}) => {
  const formRef = useRef(null);

  const updateAttachmentById = async (id) => {
    let result = null;
    try {
      result = await MrkClientServiceClient.getMrkAttachmentById(token, id);
      formRef.current.setValues({
        ...formRef.current.values, attachments: map(get(formRef, 'current.values.attachments', []), item => {
          if (get(item, 'attachment.id', null) === result.id) return { ...item, attachment: result };
          return item;
        })
      });
    } catch (error) {
      log(error);
    }
    return result;
  };

  const updateAttachment = (attachment) => {
    formRef.current.setValues({
      ...formRef.current.values, attachments: map(get(formRef, 'current.values.attachments', []), item => {
        if (get(item, 'attachment.id', null) === attachment.id) return { ...item, attachment: attachment };
        return item;
      })
    });
  };

  const signAttachment = async (attachment, keys) => {
    if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
      try {
        const cms = await signAttachmentIIT(attachment, keys.typeSign);
        if (cms === null) return;
        const newAttachment = await MrkClientServiceClient.signAttachment(token, attachment.id, cms, keys.publicKey);
        updateAttachment(newAttachment);
      } catch (error) {
        NotificationError(error, 'signAttachment');
      }
    } else if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) {
      try {
        const cms = await signAttachmentNcaLayer(attachment);
        if (cms === null) return;
        const newAttachment = await MrkClientServiceClient.signAttachment(token, attachment.id, cms.responseObject, null);
        updateAttachment(newAttachment);
      } catch (error) {
        if (error.message !== 'action.canceled') NotificationError(error, 'signAttachment');
      }
    } else {
      log('need development');
    }
  };

  useEffect(() => {
    if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) connectNcaLayer();
    subscribe(socketId, `/ws/${accountId}`, (msg) => {
      switch (msg.event) {
        case 'ATTACHMENT_EVENT_EDITING':
          updateAttachmentById(msg.id);
          break;
        case 'ATTACHMENT_SIGNED':
          updateAttachmentById(msg.id);
          break;
        default:
          break;
      }
    });
    if (!newMrkDocument) return;
    if (extRespReq === MrkDocResponceType.REQUIRED_SAME) {
      prepareDraftDocument(extRespPatternId, parentId);
    } else {
      getAllDocumentPatterns(extRespPatternId, parentId);
    }
    return () => {
      initState();
      unsubscribe(socketId);
    };
  }, []);

  const needSign = (requiredSign = false, isEditing = false, attachments = []) => {
    if(requiredSign === false) return false;
    if(isEditing) return true;
    if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN){
      return size(attachments) !== size(filter(attachments, item => {
        return get(item, 'attachment.hasDigitalSign', false);
      }));
    }
    return false;
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={Yup.object({
        document: Yup.object({
          name: Yup.string().required(I18n.t('form.required'))
        })
      })}
      innerRef={formRef}
      initialValues={{
        ...mrkDocumentData,
        items: sortBy(get(mrkDocumentData, 'items', []), ['order']),
        certificate: null,
        send: false,
        withECP: false,
        attachments: get(mrkDocumentData, 'atts', []).map(item => ({
          attachment: item,
          file: null
        }))
      }}
      onSubmit={(values) => {
        const data = new MrkDocumentData({
          ...values,
          atts: values.attachments.map(item => item.attachment)
        });
        createOrUpdateMrkDocument(data, values.send, values.certificate, values?.withECP);
      }}
    >{({ handleSubmit, values, validateForm, setTouched, setValues }) => {
      const isEditing = size(filter(values.attachments, item => {
        return get(item, 'attachment.isEditing', false);
      })) > 0;

      return (
        <Modal
          visible={true}
          width={720}
          centered
          bodyStyle={{
            height: 'calc(100vh - 180px)',
            padding: 0,
            overflowY: 'hidden'
          }}
          maskClosable={false}
          title={I18n.t('CreateMrkDocument.title')}
          onCancel={() => hideModal('MODAL_CREATE_MRK_DOCUMENT')}
          footer={step === 2 ? [
            <Row type="flex" gutter={[16, 0]} justify="end" key="step=2">
              {SEND_DOC_WITH_ECP && <Col>
                <Tooltip title={I18n.t('common.attchment_editing')} trigger={isEditing ? 'hover' : 'none'}>
                  <Button icon={<LockOutlined />} key="id" type="primary" disabled={needSign(values?.document?.requiredSign, isEditing, values.attachments)} loading={isFetching}
                    onClick={async () => {
                      const isValidForm = await validateForm();
                      setTouched(setNestedObjectValues(isValidForm, true), true);
                      if (isEmpty(isValidForm)) {
                        switch (SIGN_PROVIDER_TYPE) {
                          case SignProviderType.UA_SIGN:
                            showModal('MODAL_FILE_SIGN', {
                              submitModal: async (result) => {
                                setValues({ ...values, certificate: result, send: true, withECP: true });
                                handleSubmit();
                              }
                            });
                            break;

                          default:
                            setValues({ ...values, certificate: null, send: true, withECP: true });
                            handleSubmit();
                            break;
                        }
                      } else {
                        notification.error({
                          key: 'form_error',
                          message: I18n.t('common.error'),
                          description: I18n.t('common.form_is_invalid')
                        });
                      }
                    }}
                  >
                    {I18n.t('CreateMrkDocument.send_doc_ecp')}
                  </Button>
                </Tooltip>
              </Col>}
              <Col>
                <Tooltip title={I18n.t('common.attchment_editing')} trigger={isEditing ? 'hover' : 'none'}>
                  <Button key="back" type="primary" loading={isFetching} disabled={isEditing || values?.document?.requiredSign} onClick={async () => {
                    const isValidForm = await validateForm();
                    setTouched(setNestedObjectValues(isValidForm, true), true);
                    if (isEmpty(isValidForm)) {
                      setValues({
                        ...values, certificate: null, send: true, withECP: false
                      });
                      handleSubmit();
                    } else {
                      notification.error({
                        key: 'form_error',
                        message: I18n.t('common.error'),
                        description: I18n.t('common.form_is_invalid')
                      });
                    }
                  }}>
                    {I18n.t('CreateMrkDocument.send_doc')}
                  </Button>
                </Tooltip>
              </Col>
              <Col>
                <Button key="submit" loading={isFetching} onClick={async () => {
                  const isValidForm = await validateForm();
                  setTouched(setNestedObjectValues(isValidForm, true), true);
                  if (isEmpty(isValidForm)) {
                    setValues({
                      ...values, certificate: null, send: false, withECP: false
                    });
                    handleSubmit();
                  } else {
                    notification.error({
                      key: 'form_error',
                      message: I18n.t('common.error'),
                      description: I18n.t('common.form_is_invalid')
                    });
                  }
                }}>
                  {I18n.t('CreateMrkDocument.send_in_draft')}
                </Button>
              </Col>
            </Row>
          ] : [<Button key="submit" loading={isPrepareFetching} disabled={isEmpty(documentPattern)} onClick={() => prepareDraftDocument(documentPattern.id, parentId)}>
            {I18n.t('common.next')}
          </Button>
          ]}
        >
          {isFetching && <Loader />}
          {step === 1 && <ListDocumentPatterns extRespPatternId={extRespPatternId} parentId={parentId} />}
          {step === 2 && <FormData showModal={showModal} organization={organization} removeEcp={async (attachment, index) => {
            try {
              const newAttachment = new MrkAttachment({ ...attachment, hasDigitalSign: false, digitalSigns: [], isEditing: true });
              showModal('MODAL_ATTACHMENT_EDIT', {
                mrkAttachment: newAttachment
              });
            } catch (error) {
              log(error);
            }
          }}
            signAttachment={async (attachment) => {
              if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
                showModal('MODAL_FILE_SIGN', {
                  submitModal: (result) => signAttachment(attachment, result)
                });
              } else if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) {
                signAttachment(attachment);
              }
            }}
            ALLOWED_ATTACHMENT_UPLOAD={ALLOWED_ATTACHMENT_UPLOAD}
          />}
        </Modal>
      );
    }}
    </Formik>
  );
};
const mapStateToProps = state => ({
  token: state.auth.token,
  ALLOWED_ATTACHMENT_UPLOAD: state.settings.ALLOWED_ATTACHMENT_UPLOAD,
  SIGN_PROVIDER_TYPE: state.settings.SIGN_PROVIDER_TYPE,
  organization: state.auth.organization,
  accountId: state.auth.accountId,
  step: state.modal.createMrkDocument.step,
  documentPattern: state.modal.createMrkDocument.documentPattern,
  mrkDocumentData: state.modal.createMrkDocument.mrkDocumentData,
  isFetching: state.modal.createMrkDocument.isFetching,
  isPrepareFetching: state.modal.createMrkDocument.isPrepareFetching,
  SEND_DOC_WITH_ECP: state.settings.SEND_DOC_WITH_ECP
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideModal: actions.hideModal,
      showModal: actions.showModal,
      prepareDraftDocument,
      getAllDocumentPatterns,
      createOrUpdateMrkDocument,
      initState
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CreateMrkDocument);