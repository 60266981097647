import React from 'react';
import { Form, Input, Checkbox } from 'formik-antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { I18n } from 'react-redux-i18n';
import { useFormikContext, FieldArray } from 'formik';
import { get, indexOf } from 'lodash';
import Client from '../Client';
import { mrkClient } from 'utils/structures';
import * as styles from '../styles.module.scss';

const Organization = ({ formItemProps, MAX_CLIENTS_ORGANIZATION, MIDDLE_NAME_REQUIRED = false, login, password = false }) => {
  const { values, setFieldValue, setValues } = useFormikContext();

  const isConfirmedField = (name) => {
    const confirmedFields = get(values, 'organization.confirmedFields', []);
    return indexOf(confirmedFields, name) !== -1;
  };

  return <>
    <Form.Item name="organization.name"
      {...formItemProps}
      required={true}
      label={I18n.t('MrkOrganization.name')}
    >
      <Input name="organization.name" disabled={isConfirmedField('name')} placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>
    <Form.Item name="organization.edrpo"
      {...formItemProps}
      required={true}
      label={I18n.t('MrkOrganization.edrpo')}
    >
      <Input name="organization.edrpo" disabled={isConfirmedField('edrpo')} placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>
    <Form.Item name="organization.adress"
      {...formItemProps}
      required={true}
      label={I18n.t('MrkOrganization.adress')}
    >
      <Input name="organization.adress" disabled={isConfirmedField('adress')} placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>
    <Form.Item name="organization.regAdress"
      {...formItemProps}
      required={true}
      label={I18n.t('MrkOrganization.regAdress')}
    >
      <Input name="organization.regAdress" disabled={isConfirmedField('regAdress')} placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>
    <FieldArray
      name={'clientList'}
      render={arrayHelpers => (<>
        {get(values, 'clientList', []).map((_, index) => <div key={index} className={styles.organization}>
          <Form.Item
            {...formItemProps}
            label={<strong>{I18n.t('Profile.client')} {MAX_CLIENTS_ORGANIZATION === 1 ? (index + 1) : ''}</strong>}
            key={index}
            name={`clientList.${index}.chief`}
          >
            <Checkbox name={`clientList.${index}.chief`} size={'large'}
              disabled={get(values, 'clientList', []).length < 2}
              onChange={e => {
                if (e.target.checked) {
                  setValues({
                    ...values, clientList: get(values, 'clientList', []).map((item, idx) => {
                      return mrkClient({
                        ...item,
                        chief: index === idx
                      });
                    })
                  });
                } else {
                  setValues({
                    ...values, clientList: get(values, 'clientList', []).map((item, idx) => {
                      return mrkClient({
                        ...item,
                        chief: idx === 0
                      });
                    })
                  });
                }
              }}
            >{I18n.t('Profile.select_chief')}</Checkbox>
          </Form.Item>
          <Client prefix={`clientList.${index}.`} MIDDLE_NAME_REQUIRED={MIDDLE_NAME_REQUIRED} formItemProps={formItemProps} login={login} password={password && index === 0} />
          {get(values, 'clientList', []).length > 1 && <CloseCircleOutlined
            onClick={() => {
              if (get(values, `clientList.${index}.chief`, false)) setFieldValue('clientList.0.chief', true);
              arrayHelpers.remove(index);
            }}
            className={styles.remove} />}
        </div>)}
        {MAX_CLIENTS_ORGANIZATION > get(values, 'clientList', []).length && <Row type="flex" align="middle" justify="center">
          <Button icon={<PlusOutlined />} type="ghost" onClick={() => arrayHelpers.push(mrkClient())}>{I18n.t('Profile.add_user')}</Button>
        </Row>}
      </>
      )}
    />
  </>;
};

export default Organization;