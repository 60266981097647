import React from 'react';
import { Typography, Tooltip, notification, Row, Progress, Col, Button, Dropdown, Menu } from 'antd';
import { AttachmentRow } from 'components/Attachment';
import classnames from 'classnames';
import { map, size, reduce, filter } from 'lodash';
import DigitalInformation from './DigitalInformation';
import { CaretDown } from 'components/Icons';
import { I18n } from 'react-redux-i18n';
import { getP7sUrl, getAttachmentUrl } from 'utils/helpers';
import * as styles from '../right-preview.module.scss'; 

const AttachmentRightMenu = ({ children, menu, attachment }) => {
  if (attachment.isEditing) {
    return <>
      {children}
    </>;
  }
  return <Dropdown
    overlay={<Menu>
      {menu.map((item, index) => <Menu.Item key={index} onClick={item.onClick}>{item.title}</Menu.Item>)}
      <Menu.Item key="download">
        <a href={getAttachmentUrl(attachment, 'ORIGINAL')} download>{I18n.t('common.download')}</a>
      </Menu.Item>
      {(attachment.fType === FileType.OFFICE || attachment.fType === FileType.PDF) && <Menu.Item key="download_gen_file" 
      onClick={() => {
        notification.info({
          key: `${attachment.id}`,
          message: I18n.t('common.info'),
          description: I18n.t('notification.gen_file', { name: attachment.fileName }),
          duration: 0
        });
      }}
      >
        <a href={getAttachmentUrl(attachment, 'PDF')} download>{I18n.t('Attachment.gen_file')}</a>
      </Menu.Item>}
      {size(menu) !== 0 && size(attachment.digitalSigns) > 0 && <Menu.Divider />}
      {map(attachment.digitalSigns, (digitalSign, index) => <Menu.Item key={digitalSign.id}>
        <a href={getP7sUrl(digitalSign.id)} download>
          {`${I18n.t('common.download_p7s')} ${size(attachment.digitalSigns) === 1 ? '' : index + 1}`}
        </a>
      </Menu.Item>)}
    </Menu>}
    trigger={['click']}
  >
    <div
      className={classnames(styles.att_menu)}
    >
      {/* eslint-disable-next-line */}
      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        {I18n.t('common.functions')} <CaretDown />
      </a>
    </div>
  </Dropdown>;
};

const Attachment = ({ attachment, onClick, menu = [], removeAttachment = null, removeEcp = null, signAttachment = null, active }) => {
  const almexSigns = reduce(attachment.digitalSigns, (hash, item) => {
    hash.push(...filter(item.signDetails, { signInSystem: 'ALMEX' }));
    return hash;
  }, []);
  const externalSigns = reduce(attachment.digitalSigns, (hash, item) => {
    hash.push(...filter(item.signDetails, { signInSystem: 'EXTERNAL' }));
    return hash;
  }, []);



  return <Row
    className={classnames(styles.attachment, { [styles.att_active]: active })}
    gutter={[0, 8]}>
    <AttachmentRightMenu menu={menu} attachment={attachment}></AttachmentRightMenu>
    <Col span={24}>
      <AttachmentRow
        onClick={() => onClick(attachment)}
        attachment={attachment}
        removeAttachment={removeAttachment}
      />
    </Col>
    {signAttachment !== null && <Col span={24} className={styles.tc}>
      <Button type="primary" onClick={signAttachment}>{I18n.t('common.sign')}</Button>
    </Col>}
    {attachment.isEditing && <Col span={24}>
      <Tooltip title={I18n.t('common.attchment_editing')}>
        <Progress percent={100} showInfo={false} status="active" />
      </Tooltip>
    </Col>}
    {size(attachment.digitalSigns) > 0 && <>
      <Col span={24} style={{ paddingLeft: 31 }}>
        {size(almexSigns) > 0 && <><Row gutter={[0, 8]}>
          <Col span={24}>
            <Typography.Text className={styles.ecp_title}>{I18n.t('Attachment.signs_sender')}</Typography.Text>
          </Col>
        </Row>
          <Row gutter={[0, 8]} style={{ fontSize: 12 }}>
            <Col span={24}>
              {map(almexSigns, (digitalSign, index) => <DigitalInformation key={index} {...digitalSign} />)}
            </Col>
          </Row>
        </>}
        {size(externalSigns) > 0 && <><Row gutter={[0, 8]}>
          <Col span={24}>
            <Typography.Text className={styles.ecp_title}>{I18n.t('Attachment.signs_my_company')}</Typography.Text>
          </Col>
        </Row>
          <Row gutter={[0, 8]} style={{ fontSize: 12 }}>
            <Col span={24}>
              {map(externalSigns, (digitalSign, index) => <DigitalInformation key={index} {...digitalSign} />)}
            </Col>
          </Row>
        </>}
      </Col>
      {removeEcp !== null && <Col span={24} className={styles.tc}>
        <Button onClick={removeEcp}>{I18n.t('Attachment.remove_ecp')}</Button>
      </Col>}
    </>}
  </Row>;
};

export default Attachment;