import React from 'react';
import { Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import { UserOutlined } from '@ant-design/icons';
import { Button, Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import { login } from 'redux/actions/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { NotificationError, getQueryParam } from 'utils/helpers';
import { MrkClientServiceClient } from 'api';
import { Password } from 'components/Form';
import * as Yup from 'yup';
import * as styles from 'pages/SignIn/signin.module.scss';

const FormData = ({ login, token, isFetching, locale, PASSWORD_REG_EXP }) => {

  if (token !== null) return <Redirect
    to={{
      pathname: '/'
    }}
  />;

  return (
    <Formik
      initialValues={{
        login: getQueryParam('login'),
        code: getQueryParam('code'),
        password: '',
        confirmation: ''
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required(I18n.t('form.required')).matches(
          new RegExp(PASSWORD_REG_EXP),
          I18n.t('form.password_regexp')
        ),
        confirmation: Yup.string().required(I18n.t('form.required')).matches(
          new RegExp(PASSWORD_REG_EXP),
          I18n.t('form.password_regexp')
        )
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await MrkClientServiceClient.finishResetPassword(values.login, values.code, values.password, values.confirmation, locale);
          login(values);
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
          NotificationError(error, 'finishResetPassword');
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form}>
          <Form.Item name="login" >
            <Input 
              name="login" 
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} 
              placeholder={I18n.t('SignIn.login')} 
              size={'large'} 
              readOnly
            />
          </Form.Item>
          <Form.Item name="password">
            <Password
              name="password" 
              placeholder={I18n.t('SignIn.password')} 
              size={'large'} 
            />
          </Form.Item>
          <Form.Item name="confirmation">
            <Password
              name="confirmation"
              placeholder={I18n.t('SignIn.confirmationPassword')}
              size={'large'}
            />
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Button
                type="primary"
                block
                size={'large'}
                id="submit"
                htmlType="submit"
                disabled={isFetching || isSubmitting}
                loading={isFetching || isSubmitting}
              >
                {I18n.t('SignIn.sign_in')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  PASSWORD_REG_EXP: state.settings.PASSWORD_REG_EXP,
  token: state.auth.token,
  locale: state.i18n.locale,
  isFetching: state.auth.isFetching
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FormData);