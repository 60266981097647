import React from 'react';
import { Form, Input, InputNumber, Checkbox, Radio, Select } from 'formik-antd';
import { get, keys, map, isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { I18n } from 'react-redux-i18n';
import { DatePicker, RangePicker } from 'components/Form';
import UserChoice from './UserChoice';
import HandBook from './HandBook';

const ContentItemTemplate = ({ item, name, patternId, formItemProps }) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const settingLayout = {
    labelAlign: 'left',
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    ...formItemProps,
    name,
    label: <span>{item.content.oName}</span>,
    required: item.cRequired
  };

  const strValidation = value => {
    if (get(values, `${name}.cRequired`, false) && isEmpty(value)) return I18n.t('form.required');
    return null;
  };

  switch (item.content.type) {
    case ContentItemType.TEXT_FIELD:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        validate={strValidation}
      >
        <Input
          name={`${name}.content.value.strValue`}
          disabled={item.cReadOnly}
          placeholder={I18n.t('form.enter_value')}
          fast={true}
        />
      </Form.Item>;
    case ContentItemType.CURRENCY:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        validate={strValidation}
      >
        <InputNumber
          style={{
            width: '100%'
          }}
          onChange={e => setFieldValue(`${name}.content.value.strValue`, e === null ? e : `${e}`)}
          name={`${name}.content.value.strValue`}
          disabled={item.cReadOnly}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          parser={value => value.replace(/(\s*)/g, '')}
        />
      </Form.Item>;
    case ContentItemType.USER_CHOICE:
      return <UserChoice
        name={name}
        settingLayout={settingLayout}
        patternId={patternId}
        required={item.cRequired}
        disabled={item.cReadOnly}
      />;
    case ContentItemType.COMBO_BOX:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        validate={strValidation}
      >
        <Select
          name={`${name}.content.value.strValue`}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={item.cReadOnly}
          placeholder={I18n.t('form.enter_value')}
        >
          {map(item.content.fields).map((itm, index) => <Select.Option key={index} value={itm}>{itm}</Select.Option>)}
        </Select>
      </Form.Item >;
    case ContentItemType.CHECKBOX:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        //label={null}
        validate={strValidation}
      >
        <Checkbox
          disabled={item.cReadOnly}
          name={`${name}.content.value.strValue`}
          checked={get(values, `${name}.content.value.strValue`, 'false') === 'true'}
          onChange={(e) => setFieldValue(`${name}.content.value.strValue`, `${e.target.checked}`)}
          fast={true}
        />
        {/* <Checkbox
          disabled={item.cReadOnly}
          name={`${name}.content.value.strValue`}
          checked={get(values, `${name}.content.value.strValue`, 'false') === 'true'}
          onChange={(e) => setFieldValue(`${name}.content.value.strValue`, `${e.target.checked}`)}
        />
          {item.content.oName}
        </Checkbox> */}
      </Form.Item>;
    case ContentItemType.CALENDAR:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        validate={strValidation}
      >
        <DatePicker
          name={`${name}.content.value.strValue`}
          placeholder={I18n.t('form.select_date')}
          format={'DD.MM.YYYY HH:mm'}
          showTime={{ format: 'HH:mm' }}
          useString={true}
          disabled={item.cReadOnly}
          fast={true}
        />
      </Form.Item>;
    case ContentItemType.CALENDAR_RANGE:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        validate={strValidation}
      >
        <RangePicker
          name={`${name}.content.value.strValue`}
          format={'DD.MM.YYYY'}
          useString={true}
          disabled={item.cReadOnly}
          value={[get(values, `${name}.content.value.strValue`, null), get(values, `${name}.content.value.strValue2`, null)]}
          onChange={e => {
            setValues({
              ...values,
              items: [
                ...values.items.map(item => {
                  if (item.content.key === get(values, `${name}.content.key`, null)) {
                    const item = get(values, name, {});
                    item.content.value.strValue = e === null ? null : String(e[0].valueOf());
                    item.content.value.strValue2 = e === null ? null : String(e[1].valueOf());
                    return item;
                  }
                  return item;
                })
              ]
            });
          }}
        />
      </Form.Item>;
    case ContentItemType.MULTILINE_TEXT_FIELD:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        validate={strValidation}
      >
        <Input.TextArea
          name={`${name}.content.value.strValue`}
          rows={3}
          disabled={item.cReadOnly}
          placeholder={I18n.t('form.enter_value')}
          fast={true}
        />
      </Form.Item>;
    case ContentItemType.SWITCH_ITEM:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        validate={strValidation}
      >
        <Radio.Group name={`${name}.content.value.strValue`} disabled={item.cReadOnly} fast={true}>
          {map(item.content.fields).map((itm, index) => <Radio fast={true} key={index} value={itm}>{itm}</Radio>)}
        </Radio.Group>
      </Form.Item>;
    case ContentItemType.HAND_BOOK:
      return <HandBook
        name={name}
        settingLayout={settingLayout}
        required={item.cRequired}
        disabled={item.cReadOnly}
      />;
    default:
      return <Form.Item
        {...settingLayout}
        name={`${name}.content.value.strValue`}
        validate={strValidation}
      >
        {keys(ContentItemType)[item.content.type]}
      </Form.Item>;
  }
};

export default ContentItemTemplate;