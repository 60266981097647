import React from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import { Close } from 'components/Icons';
import * as styles from './index.module.scss';
import AttachmentDetailView from 'components/AttachmentDetailView';

const LeftOnlyOffice = ({ mrkAttachment, close }) => <Drawer
  className={styles.left_bar}
  placement={'left'}
  closable={false}
  mask={false}
  onClose={false}
  destroyOnClose={true}
  visible={mrkAttachment !== null}
  bodyStyle={{ height: '100%', padding: 'unset', paddingTop: 24, position: 'relative' }}
>
  <Close
    onClick={close}
  />
  <AttachmentDetailView 
    mrkAttachment={mrkAttachment}
  />
</Drawer>;

const mapStateToProps = state => ({
  mrkAttachment: state.mrkDocument.mrkAttachment
});
export default connect(mapStateToProps, null)(LeftOnlyOffice);