import React from 'react';
import { Input } from 'formik-antd';
import { Tooltip } from 'antd';
import { I18n } from 'react-redux-i18n';
import { InfoCircleOutlined } from '@ant-design/icons';

const Password = (props) => <Input.Password
  autoComplete="new-password"
  prefix={
    <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>
      {I18n.t('common.password_tooltip')}
    </div>}>
      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
    </Tooltip>}
  placeholder={I18n.t('form.enter_value')}
  {...props}
/>;

export default Password;