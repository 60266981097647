import React, { useEffect } from 'react';
import { Modal } from 'components/Modals';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-modals';
import { I18n } from 'react-redux-i18n';
import { MrkClientServiceClient } from 'api';
import AttachmentDetailView from 'components/AttachmentDetailView';
const AttachmentEdit = ({ token, mrkAttachment, hideModal, ATTACHMENT_EDIT_FLAG_RESET = 15 }) => {
  useEffect(() => {
    let markInterval = null;
    if(mrkAttachment?.disableEdit) return;
    const markAttachment = () => {
      MrkClientServiceClient.markAttachmentAsEditing(token, mrkAttachment.id);
    };
    if (mrkAttachment.fType === FileType.OFFICE && !mrkAttachment.hasDigitalSign) {
      markInterval = setInterval(markAttachment, Number(ATTACHMENT_EDIT_FLAG_RESET) * 1000);
      markAttachment();
    }
    return () => {
      clearTimeout(markInterval);
    };
  }, []);
  return <Modal
    visible={true}
    centered
    width={'100%'}
    style={{
      height: '100%'
    }}
    onCancel={() => hideModal('MODAL_ATTACHMENT_EDIT')}
    maskClosable={false}
    bodyStyle={{
      height: 'calc(100vh - 55px)',
      padding: 0,
      overflowY: 'hidden'
    }}
    title={I18n.t('common.editor')}
    footer={null}
  >
    <AttachmentDetailView 
      mrkAttachment={mrkAttachment} 
      onOutdatedVersion={() => hideModal('MODAL_ATTACHMENT_EDIT')} 
    />
  </ Modal>;
};

const mapStateToProps = state => ({
  token: state.auth.token,
  ATTACHMENT_EDIT_FLAG_RESET: state.settings.ATTACHMENT_EDIT_FLAG_RESET
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideModal: actions.hideModal
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AttachmentEdit);
