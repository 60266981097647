import React, { useCallback } from 'react';
import { I18n } from 'react-redux-i18n';
import { useDispatch } from 'react-redux';
import { actions } from 'react-redux-modals';
import { Typography, Row, Col, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Modal } from 'components/Modals';
import { NotificationError } from 'utils/helpers';

const SignDoc = ({ handleSuccess = () => { }, disableSend = false }) => {
  const dispatch = useDispatch();

  const onClose = useCallback(async (cert = false) => {
    try {
      handleSuccess(cert);
      dispatch(actions.hideModal('MODAL_SIGN_DOC'));
    } catch (error) {
      NotificationError(error);
    }
  }, []);

  return (
    <Modal
      visible={true}
      centered
      bodyStyle={{
        overflowY: 'hidden'
      }}
      maskClosable={false}
      title={I18n.t('SendDoc.title')}
      onCancel={() => dispatch(actions.hideModal('MODAL_SIGN_DOC'))}
      footer={<Row type="flex" gutter={[16, 0]} justify="center">
        <Col>
          <Button
            key="ceriticate_send"
            type="primary"
            icon={<LockOutlined />}
            onClick={() => onClose(true)}>
              
            {I18n.t('SendDoc.send_cert')}
          </Button>
        </Col>
        <Col>
          <Button
            key="send"
            disabled={disableSend}
            type="primary"
            onClick={() => onClose(false)}>
            {I18n.t('SendDoc.send')}
          </Button>
        </Col>
      </Row>}
    >
      <Typography.Text>
        {I18n.t('SendDoc.text')}
      </Typography.Text>
    </Modal>
  );
};
export default SignDoc;