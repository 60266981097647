import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'react-redux-modals';
import { usePromise } from 'react-use';
import { Modal } from 'components/Modals';
import ListDocumentPatterns from './ListDocumentPatterns';
import { NotificationError } from 'utils/helpers';
import Loader from 'components/Loader';
import { MrkClientServiceClient } from 'api';

const SelectPatternAttachments = ({ handleSuccess = () => { } }) => {
  const { token } = useSelector(state => ({
    token: state.auth.token
  }));
  const dispatch = useDispatch();
  const mounted = usePromise();

  const [isFetching, setIsFetching] = useState(true);
  const [patterns, setPatterns] = useState([]);
  const [selected, setSelected] = useState([]);

  const getAllDocumentPatterns = async () => {
    try {
      setIsFetching(true);
      const result = await mounted(MrkClientServiceClient.getAllDocumentPatterns(token, new KazFilter({
        position: 0,
        countFilter: 999,
        orders: [],
        items: []
      })));
      setPatterns(result);
      setIsFetching(false);
    } catch (error) {
      NotificationError(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getAllDocumentPatterns();
  }, []);

  const onOk = useCallback(async () => {
    try {
      handleSuccess(selected);
      dispatch(actions.hideModal('MODAL_SELECT_PATTERN'));
    } catch (error) {
      NotificationError(error);
    }
  }, [selected]);

  return (
    <Modal
      visible={true}
      width={720}
      centered
      bodyStyle={{
        height: 'calc(100vh - 180px)',
        overflowY: 'hidden',
        padding: 0
      }}
      maskClosable={false}
      title={I18n.t('SelectPattern.title')}
      onCancel={() => dispatch(actions.hideModal('MODAL_SELECT_PATTERN'))}
      onOk={onOk}
      okButtonProps={{ disabled: selected === null }}
      okText={I18n.t('SelectPattern.next')}
    >
      {isFetching && <Loader />}
      <ListDocumentPatterns
        documentPattern={selected}
        documentPatterns={patterns}
        setDocumentPattern={setSelected}
      />
    </Modal>
  );
};
export default SelectPatternAttachments;