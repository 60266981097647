import React, { useEffect } from 'react';
import { Layout } from 'antd';
import classnames from 'classnames';
import { LOGO } from 'constants/img';
import UserMenu from 'components/UserMenu';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPrevPath } from 'redux/actions/auth';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import * as styles from './layout-app.module.scss';
import { FacebookFilled, YoutubeFilled } from '@ant-design/icons';
const { Content } = Layout;

const Footer = (props) => <Layout.Footer className={styles.footer}
  {...props}>
  <Row type="flex" justify="space-between" align="middle">
    <Col>
      <a
        href="https://almexoft.com/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >Almexoft</a> Copyrights © {new Date().getFullYear()} {I18n.t('common.copyrights')}
    </Col>
    <Col>
      <Row>
        <Col span={12}>
          <a
            href="https://www.facebook.com/almexecm/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookFilled
              style={{
                fontSize: 20,
                paddingRight: 8
              }}
            />
          </a>
        </Col>
        <Col span={12}>
          <a
            href="https://www.youtube.com/channel/UC0YY_P-rXQn9AowYMVOzZCA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeFilled
              style={{
                fontSize: 20,
                color: 'red'
              }}
            />
          </a>
        </Col>
      </Row>
    </Col>
  </Row>
</Layout.Footer>;

const LayoutConnectApp = ({ children, location, setPrevPath }) => {
  useEffect(() => {
    setPrevPath(location ? `${location.pathname}${location.search}` : '/');
  }, []);

  return <Layout className={styles.layuot_app}>
    {children}
    <Footer />
  </Layout>;
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPrevPath
    },
    dispatch
  );
const LayoutApp = connect(null, mapDispatchToProps)(LayoutConnectApp);


const SubLayout = ({ children }) => <Layout className={styles.layuot_sub}>
  <Content style={{ display: 'flex', height: '100%' }}>
    {children}
  </Content>
</Layout>;

const HeaderLogo = ({ children, className, enableMenu = true }) =>
  <Layout.Header className={classnames(className, styles.header)}>
    <Row type="flex" justify="space-between" align="middle">
      <Col className={classnames(styles.logo)}>
        <Link to="/mrkDocuments"><img src={LOGO} alt="logo" /></Link>
      </Col>
      {children && <Col className={classnames(styles.content)}>
        {children}
      </Col>}
      {enableMenu && <Col className={classnames(styles.user_menu)}>
        <Row type="flex" justify="end" align="middle" gutter={[16, 0]}>
          <UserMenu />
        </Row>
      </Col>}
    </Row>
  </Layout.Header>;

export {
  LayoutApp,
  SubLayout,
  HeaderLogo,
  Footer
};