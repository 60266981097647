import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Select } from 'formik-antd';
import { get, unionBy, find, map, compact, debounce } from 'lodash';
import { useFormikContext } from 'formik';
import { MrkClientServiceClient } from 'api';
import { getFioAlmex, NotificationError } from 'utils/helpers';
import { I18n } from 'react-redux-i18n';
import { useMountedState, useFirstMountState } from 'react-use';

const UserChoice = ({ token, name, patternId, disabled = false, settingLayout }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const isMounted = useMountedState();
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (isFirstMount) fetchUsers(search, false);
  }, []);

  const fetchUsers = async (text, more = false) => {
    if (isMounted()) setLoading(true);
    const fioFilterItem = text !== '' ? [new FilterItem({
      field: 'FIO',
      value: text,
      condition: FilterCondition.CONTAIN,
      fType: FilterFieldType.STRING
    })] : [];

    const patterIdFilter = patternId ? [new FilterItem({
      field: 'allowedInContent',
      value: patternId,
      condition: FilterCondition.EQUAL,
      fType: FilterFieldType.STRING,
      additionValue: get(values, `${name}.content.key`, null)
    })] : [];

    const filter = new KazFilter({
      position: more ? users.length : 0,
      countFilter: 15,
      orders: [],
      items: [...fioFilterItem, ...patterIdFilter]
    });
    try {
      const result = await MrkClientServiceClient.getAllUsers(token, filter);
      if (isMounted()) {
        setUsers(more ? [...users, ...result] : [...result]);
        setSearch(text);
        setLoading(false);
      }
    } catch (error) {
      NotificationError(error, 'getAllUsers');
      if (isMounted()) setLoading(false);
    }
  };

  const fetchUsersDebounce = debounce(fetchUsers, 800);
  const currentValue = compact(get(values, `${name}.content.users`, []));
  const mergeUsers = unionBy(currentValue, users, 'id');
  const touchedField = get(touched, `${name}.content.users`, false);
  return <Form.Item
    {...settingLayout}
    hasFeedback={false}
    name={`${name}.content.users`}
    validateStatus={touchedField ? get(errors, `${name}.content.users`, null) === null ? null : 'error' : null}
    help={touchedField ? get(errors, `${name}.content.users`, null) : null}
    validate={value => {
      if (get(values, `${name}.cRequired`, false) && (value === null || value.length === 0)) return I18n.t('form.required');
      return null;
    }}
  >
    <Select
      loading={loading}
      name={`${name}.content.users`}
      showSearch
      mode="multiple"
      value={map(currentValue, item => item.id)}
      onChange={(e) => setFieldValue(`${name}.content.users`, e.map(el => new UserOrGroup(find(mergeUsers, { id: el }))))}
      onPopupScroll={e => {
        e.persist();
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          fetchUsers(search, true);
        }
      }}
      filterOption={false}
      optionLabelProp="children"
      onSearch={fetchUsersDebounce}
      disabled={disabled}
    >
      {map(mergeUsers).map((itm, index) => <Select.Option disabled={disabled} key={index} value={itm.id}>{getFioAlmex(itm)}</Select.Option>)}
    </Select>
  </Form.Item >;
};

const mapStateToProps = state => ({
  token: state.auth.token
});
export default connect(mapStateToProps)(UserChoice);