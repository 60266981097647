import CreateMrkDocument from './CreateMrkDocument';
import ChangePassword from './ChangePassword';
import GetFileSign from './GetFileSign';
import AttachmentEdit from './AttachmentEdit';
import ResetPassword from './ResetPassword';
import SelectPatternDoc from './SelectPattern';
import CreateWizardDoc from './CreateWizardDoc';
import SelectPatternAttachments from './SelectPatternAttachments';
import SignDoc from './SignDoc';
import React from 'react';
import { Modal as AModal, ConfigProvider } from 'antd';
import { getAntdLocale } from 'utils/helpers';

export const Modal = ({ children, ...props }) => <ConfigProvider getPopupContainer={node => {
  if (node) {
    return node.parentNode;
  }
  return document.body;
}} locale={getAntdLocale()}>
  <AModal {...props}>
    {children}
  </AModal>
</ConfigProvider>;

export const MODAL_CREATE_MRK_DOCUMENT = CreateMrkDocument;
export const MODAL_CHANGE_PASSWORD = ChangePassword;
export const MODAL_FILE_SIGN = GetFileSign;
export const MODAL_ATTACHMENT_EDIT = AttachmentEdit;
export const MODAL_RESET_PASSWORD = ResetPassword;
export const MODAL_SELECT_PATTERN = SelectPatternDoc;
export const MODAL_CREATE_WIZARD_DOCUMENT = CreateWizardDoc;
export const MODAL_SELECT_PATTERN_ATTS = SelectPatternAttachments;
export const MODAL_SIGN_DOC = SignDoc;