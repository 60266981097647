import { JSEncrypt } from 'jsencrypt';

// Convert a hex string to a byte array
function hexToBytes(hex) {
  let bytes = [];
  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16));
  }
  return bytes;
};

// Convert a byte array to a hex string
function bytesToHex(bytes) {
  const hex = [];
  for (let i = 0; i < bytes.length; i++) {
    hex.push((bytes[i] >>> 4).toString(16));
    hex.push((bytes[i] & 0xf).toString(16));
  }
  return hex.join('');
};

JSEncrypt.prototype.encryptLong = function (d) {
  const k = this.key;
  const maxLength = ((k.n.bitLength() + 7) >> 3) - 11;
  try {
    let lt = '';
    let ct = '';

    if (d.length > maxLength) {
      lt = d.match(/.{1,117}/g) || '';
      lt.forEach(function (entry) {
        const t1 = k.encrypt(entry);
        ct += t1;
      });
      return hexToBytes(ct);
    }
    const t = k.encrypt(d);
    const y = hexToBytes(t);
    return y;
  } catch (ex) {
    return false;
  }
};

JSEncrypt.prototype.decryptLong = function (string) {
  const k = this.getKey();
  const maxLength = (k.n.bitLength() + 7) >> 3;
  try {
    const str = bytesToHex(string);

    let ct = '';
    if (str.length > maxLength) {
      const lt = str.match(/.{1,256}/g) || '';
      lt.forEach(function (entry) {
        const t1 = k.decrypt(entry);
        ct += t1;
      });
      return ct;
    }
    const y = k.decrypt(bytesToHex(string));
    return y;
  } catch (ex) {
    return false;
  }
};

const b64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const b64pad = '=';

function hex2b64(d) {
  let b;
  let e;
  let a = '';
  for (b = 0; b + 3 <= d.length; b += 3) {
    e = parseInt(d.substring(b, b + 3), 16);
    a += b64map.charAt(e >> 6) + b64map.charAt(e & 63);
  }
  if (b + 1 === d.length) {
    e = parseInt(d.substring(b, b + 1), 16);
    a += b64map.charAt(e << 2);
  } else if (b + 2 === d.length) {
    e = parseInt(d.substring(b, b + 2), 16);
    a += b64map.charAt(e >> 2) + b64map.charAt((e & 3) << 4);
  }
  while ((a.length & 3) > 0) {
    a += b64pad;
  }
  return a;
}

JSEncrypt.prototype.encryptLong = function (string) {
  const k = this.getKey();
  try {
    let ct = '';
    const bytes = [];
    bytes.push(0);
    let byteNo = 0;
    let c;
    const len = string.length;
    let temp = 0;
    for (let i = 0; i < len; i++) {
      c = string.charCodeAt(i);
      if (c >= 0x010000 && c <= 0x10ffff) {
        byteNo += 4;
      } else if (c >= 0x000800 && c <= 0x00ffff) {
        byteNo += 3;
      } else if (c >= 0x000080 && c <= 0x0007ff) {
        byteNo += 2;
      } else {
        byteNo += 1;
      }
      if (byteNo % 117 >= 114 || byteNo % 117 === 0) {
        if (byteNo - temp >= 114) {
          bytes.push(i);
          temp = byteNo;
        }
      }
    }

    if (bytes.length > 1) {
      for (let i = 0; i < bytes.length - 1; i++) {
        let str;
        if (i === 0) {
          str = string.substring(0, bytes[i + 1] + 1);
        } else {
          str = string.substring(bytes[i] + 1, bytes[i + 1] + 1);
        }
        const t1 = k.encrypt(str);
        ct += t1;
      }
      if (bytes[bytes.length - 1] !== string.length - 1) {
        const lastStr = string.substring(bytes[bytes.length - 1] + 1);
        ct += k.encrypt(lastStr);
      }
      return hex2b64(ct);
    }
    const t = k.encrypt(string);
    const y = hex2b64(t);
    return y;
  } catch (ex) {
    return false;
  }
};

export const setContentByPublicKey = (pubKey, content) => {
  if (!pubKey || !content) {
    return '';
  };
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(pubKey);
  const rsaPassWord = encryptor.encryptLong(content);
  return rsaPassWord;
};