import React from 'react';
import { SubLayout, HeaderLogo } from 'components/LayoutApp';
import FormData from './components/FormData';
const Profile = () => <>
  <HeaderLogo />
  <SubLayout>
    <FormData />
  </SubLayout>
</>;

export default Profile;