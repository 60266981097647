import React from 'react';
import { Row } from 'antd';
import { Form, Input, DatePicker } from 'formik-antd';
import { useFormikContext } from 'formik';
import { I18n } from 'react-redux-i18n';
import { get, map } from 'lodash';
import ContentItemTemplate from 'components/ContentItemTemplate';
import Scrollbar from 'components/Scrollbar';
import moment from 'moment';
import * as styles from '../../index.module.scss';
const FormData = () => {
  const { values, setFieldValue } = useFormikContext();
  const formItemProps = {
    labelAlign: 'left',
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    colon: false,
    className: styles.form_item
  };
  return (
    <Scrollbar>
      <div style={{ padding: '12px 24px' }}>
        <Row style={{ marginTop: 12 }} >
          <Form layout={'horizontal'} style={{ width: '100%' }}>
            <Form.Item
              {...formItemProps}
              name="document.name"
              required
              label={<span>{I18n.t('MrkDocument.name')}</span>}
            >
              <Input.TextArea name="document.name" rows={3} placeholder={I18n.t('form.enter_value')} />
            </Form.Item>
            <Form.Item
              {...formItemProps}
              name="document.documentNumber"
              label={<span>{I18n.t('MrkDocument.documentNumber')}</span>}
            >
              <Input name="document.documentNumber" placeholder={I18n.t('form.enter_value')} />
            </Form.Item>
            <Form.Item
              {...formItemProps}
              name="document.documentRegDate"
              label={<span>{I18n.t('MrkDocument.documentRegDate')}</span>}
            >
              <DatePicker
                name={'document.documentRegDate'}
                placeholder={I18n.t('form.select_date')}
                format={'DD.MM.YYYY HH:mm'}
                showTime={{ format: 'HH:mm' }}
                value={get(values, 'document.documentRegDate', -1) === -1 ? null : moment(get(values, 'document.documentRegDate', null))}
                onChange={(e) => setFieldValue('document.documentRegDate', e === null ? -1 : e.valueOf())}
              />
            </Form.Item>
            {map(values.items, (item, index) => item.cVisible ? <ContentItemTemplate
              key={index}
              item={item}
              name={`items.${index}`}
              patternId={get(values, 'document.patternId', null)}
              formItemProps={formItemProps}
            /> : null)}
          </Form>
        </Row>
      </div>
    </Scrollbar>
  );
};

export default FormData;