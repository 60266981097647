import React from 'react';
import { Layout, Row, Col } from 'antd';
import Languages from 'pages/SignIn/components/Languages';
import FormData from './components/FormData';
import Logo from 'components/Logo';
import { Footer } from 'components/LayoutApp';

import * as styles from 'pages/SignIn/signin.module.scss';

const { Content } = Layout;
const SetPassword = () => <Layout className={styles.container}>
  <Content className={styles.wrapper}>
    <Row className={styles.content} gutter={[0, 16]}>
      <Col>
        <Logo />
      </Col>
      <Col>
        <Languages />
      </Col>
      <Col>
        <FormData />
      </Col>
    </Row>
  </Content>
  <Footer style={{ background: '#fff' }} />
</Layout>;

export default SetPassword;