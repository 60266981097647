import { NotificationError } from 'utils/helpers';
import { notification } from 'antd';
import { get } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { SignDataIIT, signDataNcaLayer } from 'utils/sign';
export const GET_INFO_REQUEST = 'PAGE_PROFILE/GET_INFO_REQUEST';
export const GET_INFO_SUCCESS = 'PAGE_PROFILE/GET_INFO_SUCCESS';
export const GET_INFO_FAILURE = 'PAGE_PROFILE/GET_INFO_FAILURE';

export const getFullAccountInfo = () => {
  return async (dispatch, getState, api) => {
    dispatch({ type: GET_INFO_REQUEST });
    try {
      const {
        auth: { token }
      } = getState();
      const result = await api.MrkClientServiceClient.getFullAccountInfo(
        token
      );
      dispatch({
        type: GET_INFO_SUCCESS,
        payload: result
      });
    } catch (error) {
      NotificationError(error, 'getFullAccountInfo');
      dispatch({ type: GET_INFO_FAILURE });
    }
  };
};


export const CHANGE_INFO_REQUEST = 'PAGE_PROFILE/CHANGE_INFO_REQUEST';
export const CHANGE_INFO_SUCCESS = 'PAGE_PROFILE/CHANGE_INFO_SUCCESS';
export const CHANGE_INFO_FAILURE = 'PAGE_PROFILE/CHANGE_INFO_FAILURE';

export const changeAccountInfo = (values, sign = true) => {
  return async (dispatch, getState, api) => {
    dispatch({ type: CHANGE_INFO_REQUEST });
    try {
      const {
        auth: { token },
        settings: { SIGN_PROVIDER_TYPE }
      } = getState();
      const result = await api.MrkClientServiceClient.changeAccountInfo(
        token,
        new MrkAccount(values)
      );
      if (sign && SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
        dispatch(signProfile(values.certificate));
      } else if (sign && SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) {
        dispatch(signProfile());
      } else {
        dispatch({
          type: CHANGE_INFO_SUCCESS,
          payload: result
        });
        notification.success({
          key: 'changeAccountInfo',
          message: I18n.t('notification.profile_update_message'),
          description: I18n.t('notification.profile_update_description')
        });
      }
    } catch (error) {
      NotificationError(error, 'changeAccountInfo');
      dispatch({ type: CHANGE_INFO_FAILURE });
    }
  };
};

export const signProfile = (result) => {
  return async (dispatch, getState, api) => {
    try {
      const {
        auth: { token },
        settings: { SIGN_PROVIDER_TYPE }
      } = getState();
      const infoForSing = await api.MrkClientServiceClient.getProfileInfoForSing(token);
      let signature = null;
      if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
        signature = await SignDataIIT(infoForSing, false);
      }
      if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) {
        const dataFromNcaLayer = await signDataNcaLayer(btoa(infoForSing));
        signature = dataFromNcaLayer.responseObject;
      }
      await api.MrkClientServiceClient.signProfile(token, signature, get(result, 'publicKey', null));
      const mrkAccount = await api.MrkClientServiceClient.getFullAccountInfo(
        token
      );
      dispatch({
        type: CHANGE_INFO_SUCCESS,
        payload: mrkAccount
      });
      notification.success({
        key: 'signProfile',
        message: I18n.t('common.info'),
        description: I18n.t('notification.profile_sign_description')
      });
    } catch (error) {
      if (error.message !== 'action.canceled') NotificationError(error, 'signProfile');
    }
  };
};