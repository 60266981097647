import React from 'react';
import { PaperClipOutlined } from '@ant-design/icons';
import { Row, Col, Button, Upload, notification } from 'antd';
import { Form, } from 'formik-antd';
import { useFormikContext, FieldArray } from 'formik';
import { I18n } from 'react-redux-i18n';
import { get, size, map, compact } from 'lodash';
import { useSelector } from 'react-redux';
import { UploadFile } from 'components/Attachment';
import Attachment from 'components/RightMrkDocumentInfo/components/Attachment';
import Scrollbar from 'components/Scrollbar';
import { confirmationAction, getFileExt } from 'utils/helpers';
import * as styles from '../../index.module.scss';
const FormData = ({ showModal, removeEcp, signAttachment }) => {
  const { ALLOWED_ATTACHMENT_UPLOAD } = useSelector(state => ({
    ALLOWED_ATTACHMENT_UPLOAD: state.settings.ALLOWED_ATTACHMENT_UPLOAD,
  }));
  const { values } = useFormikContext();
  const formItemProps = {
    labelAlign: 'left',
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
    colon: false,
    className: styles.form_item
  };
  return (
    <Scrollbar>
      <div style={{ padding: '12px 24px' }}>
        <Row style={{ marginTop: 12 }} >
          <Form layout={'horizontal'} style={{ width: '100%' }}>
            <FieldArray
              name="attachments"
              render={arrayHelpers => (
                <Form.Item
                  {...formItemProps}
                  hasFeedback={false}
                  name={`attachments`}
                  label={null}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Upload
                        fileList={[]}
                        multiple={true}
                        className={styles.width100}
                        accept={ALLOWED_ATTACHMENT_UPLOAD}
                        beforeUpload={(file) => {
                          if (ALLOWED_ATTACHMENT_UPLOAD.split(',').findIndex(ext => ext === `.${getFileExt(file.name)}`) !== -1) {
                            arrayHelpers.push({
                              attachment: null,
                              file
                            });
                          } else {
                            notification.error({
                              key: 'error_allowed',
                              message: I18n.t('common.error'),
                              description: <>
                                {I18n.t('notification.error_allowed_attachment')}<br />
                                {ALLOWED_ATTACHMENT_UPLOAD}
                              </>,
                              duration: 6
                            });
                          }
                          return false;
                        }}>
                        <Row type={'flex'} gutter={[16, 0]} align="middle" justify="center">
                          <Col>
                            <Button icon={<PaperClipOutlined />}>{I18n.t('common.add_file')}</Button>
                          </Col>
                          <Col>
                            <Button htmlType="button" onClick={(e) => {
                              e.stopPropagation();
                              showModal('MODAL_SELECT_PATTERN_ATTS', {
                                patternId: values.document.patternId,
                                documentId: values.document.id,
                                handleSuccess: (atts) => {
                                  map(atts, item => arrayHelpers.push({
                                    attachment: item,
                                    file: null
                                  }));
                                }
                              });
                            }} icon={<PaperClipOutlined />}>{I18n.t('CreateWizardDoc.attsFromTemplate')}</Button>
                          </Col>
                        </Row>
                      </Upload>
                    </Col>
                    {map(values.attachments, (item, index) => <Col
                      span={24}
                      key={index}
                    >
                      {item.attachment === null ?
                        <UploadFile
                          file={item.file}
                          setAttachment={(el) => {
                            if (el) arrayHelpers.replace(index, {
                              attachment: el,
                              file: null
                            });
                          }}
                          removeAttachment={() => {
                            arrayHelpers.remove(index);
                          }}
                          documentId={values.document.id}
                        /> :
                        <Attachment
                          onClick={() => showModal('MODAL_ATTACHMENT_EDIT', {
                            mrkAttachment: item.attachment
                          })}
                          removeAttachment={(get(item, 'attachment.isEditing', false) || get(item, 'attachment.disableRemove', false)) ? null : (e) => {
                            e.stopPropagation();
                            confirmationAction(() => { arrayHelpers.remove(index); }, I18n.t('confirmation.remove_attachment', { name: item.attachment.fileName }));
                          }}
                          removeEcp={(e) => {
                            e.stopPropagation();
                            confirmationAction(() => removeEcp(item.attachment, index), I18n.t('confirmation.remove_ecp', { name: item.attachment.fileName }));
                          }}
                          attachment={item.attachment}
                          signAttachment={!get(item, 'attachment.isEditing', false) ? () => signAttachment(item.attachment) : null}
                          menu={compact([
                            !get(item, 'attachment.isEditing', false) ? {
                              title: I18n.t('common.sign'),
                              onClick: () => signAttachment(item.attachment)
                            } : null,
                            (!get(item, 'attachment.isEditing', true) && !get(item, 'attachment.disableRemove', true)) ? {
                              title: I18n.t('common.remove'),
                              onClick: () => confirmationAction(() => arrayHelpers.remove(index), I18n.t('confirmation.remove_attachment', { name: item.attachment.fileName }))
                            } : null,
                            size(item?.attachment?.digitalSigns) > 0 ? {
                              title: I18n.t('Attachment.remove_ecp'),
                              onClick: () => confirmationAction(() => removeEcp(item.attachment, index), I18n.t('confirmation.remove_ecp_and_edit', { name: item.attachment.fileName }))
                            } : null
                          ])}
                        />
                      }
                    </Col>)}
                  </Row>
                </Form.Item>
              )}
            />
          </Form>
        </Row>
      </div>
    </Scrollbar>
  );
};

export default FormData;