import React from 'react';
import moment from 'moment';
import { compact } from 'lodash';
import { DatePicker } from 'antd';
import { isDataTime } from 'utils/helpers';
import { Field } from 'formik';

const RangePickerCustom = ({ useString = false, name, value = [], ...props }) => {
  const newValue = compact(value.map(itm => {
    return isDataTime(itm) ? moment(useString ? Number(itm) : itm) : null;
  }));
  return <Field name={name}>
    {() => (
      <DatePicker.RangePicker
        {...props}
        value={newValue}
      />
    )}
  </Field>;
};

export default RangePickerCustom;