import React from 'react';
import { LOGO } from 'constants/img';
import { Link } from 'react-router-dom';

import * as styles from './logo.module.scss';

const Logo = () => <Link to="/">
  <img src={LOGO} alt="logo" className={styles.logo} />
</Link>;

export default Logo;