import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ScriptLoader from 'react-render-props-script-loader';
import Loader from 'components/Loader';
import { uniqueId } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { getOnlyOfficeUrl, getAttachmentUrl, getFio, getTypeOnlyOffice, onlyOfficeCallBackUrl, getAttachmentName, getAttachmentExt } from 'utils/helpers';
import Empty from 'components/Empty';
import { isMobile } from 'react-device-detect';
const editorId = uniqueId('editor_');
let editor = null;
const AttachmentDetailView = ({ mrkAttachment, client, onOutdatedVersion = () => { }, locale, disabled = false }) => {

  const loadEditor = () => {
    if (mrkAttachment.fType !== FileType.OFFICE && mrkAttachment.fType !== FileType.PDF) return null;
    if (editor !== null) editor.destroyEditor();
    editor = new DocsAPI.DocEditor(editorId, {
      'events': {
        'onOutdatedVersion': onOutdatedVersion
      },
      'document': {
        'fileType': getAttachmentExt(mrkAttachment),
        'key': `${mrkAttachment.id}_${mrkAttachment.fileVersion}`,
        'permissions': {
          'download': false, 
          'edit': !mrkAttachment.disableEdit,
          'print': !mrkAttachment.disableEdit,
          'review': !mrkAttachment.disableEdit
        },
        'title': getAttachmentName(mrkAttachment),
        'url': getAttachmentUrl(mrkAttachment, 'ORIGINAL')
      },
      'editorConfig': {
        'callbackUrl': onlyOfficeCallBackUrl(mrkAttachment),
        'lang': locale,
        'mode': 'edit',
        'user': {
          'id': client.id,
          'name': getFio(client)
        },
        'plugins': {
          'url': '',
          'pluginsData': []
        }
      },
      'documentType': getTypeOnlyOffice(mrkAttachment),
      'height': '100%',
      'width': '100%',
      'type': isMobile ? 'mobile' : 'desktop'
    });
  };

  useEffect(() => {
    if (editor !== null && mrkAttachment !== null) setTimeout(loadEditor, 200);
  }, [mrkAttachment]);

  useEffect(() => {
    return () => {
      if (editor !== null) {
        editor.destroyEditor();
        editor = null;
      }
    };
  }, []);

  return <ScriptLoader
    type="text/javascript"
    src={getOnlyOfficeUrl()}
    onLoad={() => setTimeout(loadEditor, 200)}
  >
    {({ loading, error }) => {
      if (loading) return <Loader />;
      if (error) return <h3>Failed to load onlyOfffice: {error.message}</h3>;
      return <div id={editorId} style={{
        height: '100%'
      }}>
        {mrkAttachment !== null && <>
          {mrkAttachment.fType === FileType.OTHER && <Empty description={I18n.t('common.no_view_or_edit_attachment')} />}
          {mrkAttachment.fType === FileType.PICTURE && <img src={getAttachmentUrl(mrkAttachment, 'ORIGINAL')} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: 8 }} alt={mrkAttachment.fileName} />}
        </>}
      </div>;
    }}
  </ScriptLoader>;
};

const mapStateToProps = state => ({
  client: state.auth.client,
  locale: state.i18n.locale
});
export default connect(mapStateToProps)(AttachmentDetailView);
