import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox } from 'formik-antd';
import { Password } from 'components/Form';
import { Formik } from 'formik';
import { Button, Typography, Row, Col, notification } from 'antd';
import { SignDataIIT, connectNcaLayer, signDataNcaLayer } from 'utils/sign';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-modals';
import { Client, Organization } from 'components/FormData';
import { mrkClient, mrkOrganization } from 'utils/structures';
import Loader from 'components/Loader';
import { MrkClientServiceClient } from 'api';
import { Redirect } from 'react-router-dom';
import * as styles from '../../signup.module.scss';
import { map, get } from 'lodash';
import * as Yup from 'yup';
import { NotificationError } from 'utils/helpers';
import { MrkClientSchema, MrkOrganizationSchema } from 'constants/schema';
import Scrollbar from 'components/Scrollbar';

const FormData = ({ locale, isFetching, isSaving, MIDDLE_NAME_REQUIRED = false, PASSWORD_REG_EXP, SIGN_PROVIDER_TYPE, showModal }) => {
  const [isRegistration, setRegistration] = useState(false);
  const formItemProps = {
    labelAlign: 'left',
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
  };

  useEffect(() => {
    if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) connectNcaLayer();
  }, []);

  if (isRegistration) return <Redirect
    to={{
      pathname: '/signIn'
    }}
  />;

  const dataFromServer = async (values, setValues) => {
    try {
      if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
        showModal('MODAL_FILE_SIGN', {
          submitModal: async () => {
            try {
              const result = await SignDataIIT('registration', false);
              try {
                const data = await MrkClientServiceClient.registrationByKey(result, values.login, values.password, locale);
                setValues({
                  ...values,
                  ...data,
                  useOrganization: data.organization !== null,
                  clientList: map(data?.clientList, client => new MrkClient({
                    ...client,
                    birthDate: client?.birthDate === 0 ? -1 : client?.birthDate,
                    contacts: client?.contacts === null ? [] : client?.contacts
                  })),
                  dateFormEcp: true
                });
              } catch (error) {
                NotificationError(error);
              }
            } catch (error) {
              notification.error({
                key: 'key_cert',
                message: I18n.t('errors.UNKNOWN_ERROR'),
                description: get(error, 'message', I18n.t('errors.UNKNOWN_ERROR')),
              });
            }
          }
        });
      }
      if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) {
        const dataFromNcaLayer = await signDataNcaLayer(btoa('registration'));
        const data = await MrkClientServiceClient.registrationByKey(dataFromNcaLayer.responseObject, values.login, values.password, locale);
        setValues({
          ...values,
          ...data,
          useOrganization: data.organization !== null,
          clientList: map(data?.clientList, client => new MrkClient({
            ...client,
            birthDate: client?.birthDate === 0 ? -1 : client?.birthDate,
            contacts: client?.contacts === null ? [] : client?.contacts
          })),
          dateFormEcp: true
        });
      }
    } catch (error) {
      NotificationError(error);
    }
  };

  return <Scrollbar>
    <div className={styles.wrapper}>
      <Row type="flex" align="middle" justify="center">
        <Typography.Title level={3}>{I18n.t('SignUp.title')}</Typography.Title>
      </Row>
      {isFetching ? <Loader /> : <Formik
        enableReinitialize={true}
        initialValues={{
          login: '',
          password: '',
          confirmationPassword: '',
          ...new MrkAccount({
            clientList: [mrkClient({ chief: true })],
            organization: null
          }),
          useOrganization: false,
          useEcp: true,
          dateFormEcp: false
        }}
        validationSchema={Yup.object().shape({
          login: Yup.string().nullable().when('useEcp', {
            is: true,
            then: Yup.string().nullable().required(I18n.t('form.required'))
          }),
          password: Yup.string().nullable().required(I18n.t('form.required')).matches(
            new RegExp(PASSWORD_REG_EXP),
            I18n.t('form.password_regexp')
          ),
          confirmationPassword: Yup.string().nullable().required(I18n.t('form.required')).matches(
            new RegExp(PASSWORD_REG_EXP),
            I18n.t('form.password_regexp')
          ).oneOf([Yup.ref('password'), null], I18n.t('form.confirmationPassword')),
          organization: Yup.object().nullable().when('useOrganization', {
            is: true,
            then: MrkOrganizationSchema()
          }),
          clientList: Yup.array().nullable().when(['useEcp', 'dateFormEcp'], (useEcp, dateFormEcp) => {
            return (useEcp === false || dateFormEcp) ? Yup.array().min(1).max(1).of(MrkClientSchema()) : Yup.array();
          })
        })}
        validateOnMount={true}
        onSubmit={async (values) => {
          try {
            if (values.useEcp) {
              await MrkClientServiceClient.finishRegistrationByKey(new MrkAccount(values), locale);
              setRegistration(true);
              notification.success({
                key: 'registration',
                message: I18n.t('notification.registration_message'),
                description: I18n.t('notification.registration_description'),
              });
            } else {
              await MrkClientServiceClient.registration(values.clientList[0], values.password, values.organization, 'ru');
              setRegistration(true);
              notification.success({
                key: 'registration',
                message: I18n.t('notification.registration_message'),
                description: I18n.t('notification.registration_description'),
              });
            }
          } catch (error) {
            NotificationError(error, 'registration');
          }
        }}
      >
        {({ setValues, setErrors, setTouched, values, isValid, handleSubmit }) => {
          const { useEcp, dateFormEcp } = values;
          return (
            <Form layout={'horizontal'} className={styles.form}>
              <Row type="flex" align="middle" justify="center" gutter={[16, 16]}>
                {dateFormEcp === false && <>
                  <Col span={24}>
                    <Form.Item
                      {...formItemProps}
                      label={I18n.t('SignUp.useEcpTitle')}
                      name={'useEcp'}
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox name={'useEcp'} onChange={e => {
                        setErrors({});
                        setTouched({}, false);
                        setValues({ ...values, useEcp: e.target.checked });
                      }} size={'large'} />
                    </Form.Item>
                  </Col>
                  {useEcp && <>
                    <Col span={24}>
                      <Typography.Text strong>{I18n.t('Profile.info_basic')}</Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={`login`}
                        {...formItemProps}
                        required={true}
                        label={I18n.t('MrkClient.login')}
                      >
                        <Input name={`login`}
                          onKeyPress={e => {
                            const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                            const regex = new RegExp('^[a-zA-Z0-9@.]+$');
                            if (!regex.test(key)) {
                              e.preventDefault();
                              return false;
                            }
                          }} placeholder={I18n.t('form.enter_value')} size={'large'} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={'password'}
                        {...formItemProps}
                        required={true}
                        label={I18n.t('SignIn.password')}
                      >
                        <Password
                          name={'password'}
                          placeholder={I18n.t('form.enter_value')}
                          size={'large'}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={'confirmationPassword'}
                        {...formItemProps}
                        required={true}
                        label={I18n.t('SignIn.confirmationPassword')}
                      >
                        <Password
                          name={'confirmationPassword'}
                          placeholder={I18n.t('form.enter_value')}
                          size={'large'}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="button" disabled={!isValid} onClick={() => {
                        dataFromServer(values, setValues);
                      }}>
                        {I18n.t('SignUp.readEcp')}
                      </Button>
                    </Col>
                  </>}
                </>}
                {(useEcp === false || dateFormEcp) && <>
                  <Col span={24}>
                    <Form.Item
                      {...formItemProps}
                      label={I18n.t('Profile.type_account')}
                      name={'useOrganization'}
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox name={'useOrganization'} onChange={e => {
                        setErrors({});
                        setTouched({}, false);
                        setValues({ ...values, useOrganization: e.target.checked, organization: e.target.checked ? mrkOrganization() : null });
                      }} size={'large'} disabled={dateFormEcp}>{I18n.t('Profile.legal_entity')}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Typography.Text strong>{I18n.t(values.useOrganization ? 'Profile.info_legal_entity' : 'Profile.info_basic')}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    {values.useOrganization ?
                      <Organization formItemProps={formItemProps} MIDDLE_NAME_REQUIRED={MIDDLE_NAME_REQUIRED} login={!dateFormEcp} password={!dateFormEcp} MAX_CLIENTS_ORGANIZATION={1} /> :
                      <Client prefix="clientList.0." MIDDLE_NAME_REQUIRED={MIDDLE_NAME_REQUIRED} login={!dateFormEcp} password={!dateFormEcp} formItemProps={formItemProps} />
                    }
                  </Col>
                </>}
                <Col span={24}>
                  <Row type="flex" justify="space-between" align="middle">
                    <Col>
                      <Button disabled={isSaving} htmlType="reset">
                        {I18n.t('common.reset')}
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="button" onClick={() => {
                        handleSubmit();
                        if (!isValid) notification.error({
                          key: 'form_error',
                          message: I18n.t('common.error'),
                          description: I18n.t('common.form_is_invalid')
                        });
                      }} loading={isSaving} disabled={useEcp && dateFormEcp === false}>
                        {I18n.t('SignUp.registration')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>);
        }}
      </Formik>}
    </div>
  </Scrollbar>;
};

const mapStateToProps = state => ({
  locale: state.i18n.locale,
  PASSWORD_REG_EXP: state.settings.PASSWORD_REG_EXP,
  SIGN_PROVIDER_TYPE: state.settings.SIGN_PROVIDER_TYPE,
  MIDDLE_NAME_REQUIRED: state.settings.MIDDLE_NAME_REQUIRED
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideModal: actions.hideModal,
      showModal: actions.showModal
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FormData);