import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SET_PREV_PATH
} from '../actions/auth';

const initialState = {
  token: localStorage.getItem('token') || null,
  ...new MrkClientSession(),
  isFetching: false,
  prevUrl: '/'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        token: action.payload.id,
        ...action.payload
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case LOGOUT_SUCCESS:
    case LOGOUT_FAILURE:
      return {
        ...state,
        ...initialState,
        token: null,
        prevUrl: '/'
      };
    case SET_PREV_PATH: 
      return {
        ...state,
        prevUrl: action.payload
      };
    default:
      return state;
  }
};
