import React from 'react';
import { Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import { login } from 'redux/actions/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { actions } from 'react-redux-modals';
import * as Yup from 'yup';
import * as styles from '../../signin.module.scss';

const FormData = ({ login, token, isFetching, prevUrl, showModal }) => {

  if (token !== null) {
    const prev = prevUrl ? prevUrl.split('?') : ['/'] ;
    return <Redirect
      to={{
        pathname: prev[0],
        search: prev.length > 1 ? `?${prev[1]}` : '' ,
      }}
    />;
  };

  return (
    <Formik
      initialValues={{
        login: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        login: Yup.string().required(I18n.t('form.required')),
        password: Yup.string().required(I18n.t('form.required'))
      })}
      onSubmit={(values) => {
        login(values);
      }}
    >
      {() => (
        <Form className={styles.form}>
          <Form.Item name="login" >
            <Input name="login" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={I18n.t('SignIn.login')} size={'large'} />
          </Form.Item>
          <Form.Item name="password" extra={null} >
            <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} name="password" placeholder={I18n.t('SignIn.password')} size={'large'} />
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Button
                type="primary"
                block
                size={'large'}
                id="submit"
                htmlType="submit"
                loading={isFetching}
              >
                {I18n.t('SignIn.sign_in')}
              </Button>
            </Col>
            <Col span={24}>
              <Button
                block
                size={'large'}
                htmlType="button"
              >
                <Link to={'/signUp'}>{I18n.t('SignIn.sign_up')}</Link>
              </Button>
            </Col>
            <Col span={24}>
              <Button
                block
                onClick={() => showModal('MODAL_RESET_PASSWORD')}
                size={'middle'}
                type="link"
                htmlType="button"
              >
                {I18n.t('SignIn.reset_password')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  token: state.auth.token,
  locale: state.i18n.locale,
  isFetching: state.auth.isFetching,
  prevUrl: state.auth.prevUrl
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      showModal: actions.showModal
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FormData);