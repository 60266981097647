import React, { useRef } from 'react';
import { Table as ATable } from 'antd';
import Empty from 'components/Empty';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import * as styles from './table.module.scss';
import Scrollbar from 'components/Scrollbar';
const Table = ({
  loading = false,
  size = 'middle',
  tableLayout = 'fixed',
  bordered = false,
  columns = [],
  dataSource = [],
  rowKey = record => record.id,
  locale = {},
  pagination = {},
  ellipsis = true,
  className,
  components,
  ref,
  ...props
}) => {
  const { emptyText } = locale;
  const tableRef = useRef(null);
  const scrollHeader = (left) => {
    const el = tableRef.current
      .closest('.ant-table-container')
      .getElementsByClassName('ant-table-header');
    if (el.length === 0) return;
    el[0].scrollLeft = left;
  };
  return (
    <div className={classnames(styles.table, className)}>
      <ATable
        tableLayout={tableLayout}
        bordered={bordered}
        size={size}
        loading={loading}
        locale={{
          ...locale,
          emptyText: isEmpty(emptyText) ? <Empty /> : emptyText
        }}
        pagination={false}
        scroll={{ y: '100%', x: false }}
        columns={columns}
        rowKey={rowKey}
        hasData={dataSource.length === 0}
        dataSource={dataSource}
        components={{
          ...components,
          table: ({ children, ...props }) => {
            return (
              <Scrollbar
                onScrollFrame={(values) => scrollHeader(values.scrollLeft)}
              >
                <table
                  ref={tableRef}
                  style={{
                    width: '100%',
                    tableLayout: 'fixed',
                    minWidth: '100%',
                  }}
                  {...props}
                >
                  {children}
                </table>
              </Scrollbar>
            );
          }
        }}
        {...props}
      />
    </div>
  );
};

export default Table;
