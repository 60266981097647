import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignIn from 'pages/SignIn';
import SetPassword from 'pages/SetPassword';
import SignUp from 'pages/SignUp';
import MrkDocuments from 'pages/MrkDocuments';
import Profile from 'pages/Profile';
import Lab from 'pages/Lab';
import ConfirmationEmail from 'pages/ConfirmationEmail';
import Loader from 'components/Loader';
import PrivateRoute from 'components/PrivateRoute';
import StartAppFail from 'components/StartAppFail';
import { LayoutApp } from 'components/LayoutApp';
import { uniqueId } from 'lodash';
import { ConfigProvider, notification } from 'antd';
import { getAntdLocale } from 'utils/helpers';
import { subscribe, unsubscribe } from 'api';
import { socket } from 'redux/actions/socket';
import { I18n } from 'react-redux-i18n';
const socketId = uniqueId('socket_app_');
const socketId2 = uniqueId('socket_app_');

const App = ({ loading, error, VERSION, accountId = null }) => {

  useEffect(() => {
    if (VERSION && VERSION !== MRK_CURRENT_VERSION) {
      notification.warning({
        message: I18n.t('notification.warning'),
        description: <p style={{ whiteSpace: 'pre-line' }}>{I18n.t('notification.version_not_equal', { vServer: VERSION, vClient: window.MRK_CURRENT_VERSION })}</p>,
        duration: 0
      });
    }
  }, [VERSION]);

  useEffect(() => {
    if (accountId === null) {
      unsubscribe(socketId);
    } else {
      subscribe(socketId, `/ws/${accountId}`, socket);
      subscribe(socketId2, `/ws/notification`, socket);
    }
  }, [accountId]);

  if (error !== null) return <StartAppFail />;

  return (
    <>
      {loading ? <Loader /> :
        <ConfigProvider locale={getAntdLocale()} getPopupContainer={node => {
          if (node) {
            return node.parentNode;
          }
          return document.body;
        }}>
          <Switch>
            <Route path="/signIn" component={SignIn} />
            <Route path="/SetPassword" component={SetPassword} />
            <Route path="/SignUp" component={SignUp} />
            <Route path="/Lab" component={Lab} />
            <Route path="/confirm-email" component={ConfirmationEmail} />
            <LayoutApp>
              <Switch>
                <PrivateRoute path="/mrkDocuments" component={MrkDocuments} />
                <PrivateRoute path="/profile" component={Profile} />
                <Redirect to="/mrkDocuments" />
              </Switch>
            </LayoutApp>
            <Redirect to="/" />
          </Switch>
        </ConfigProvider>}
    </>
  );
};

const mapStateToProps = state => ({
  accountId: state.auth.accountId,
  loading: state.asyncInitialState.loading,
  error: state.asyncInitialState.error,
  VERSION: state.settings.VERSION
});

export default connect(mapStateToProps)(App);