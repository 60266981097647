import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Typography, Row, notification } from 'antd';
import { Formik, setNestedObjectValues } from 'formik';
import { I18n } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';
import { getMrkDocuments } from 'redux/actions/mrkDocuments';
import { actions } from 'react-redux-modals';
import { get, map, isEmpty, uniqueId, size, reduce, find, compact, filter, sortBy } from 'lodash';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import { usePromise } from 'react-use';
import { Modal } from 'components/Modals';
import { log, confirmationAction, NotificationError } from 'utils/helpers';
import Loader from 'components/Loader';
import { MrkClientServiceClient, subscribe, unsubscribe } from 'api';
import { signAttachmentIIT, SignDataIIT, signDataNcaLayer, connectNcaLayer, signAttachmentNcaLayer } from 'utils/sign';
import * as Yup from 'yup';
const socketId = uniqueId('socket_update_doc_');

const CreateMrkDocument = ({
  parentId,
  extRespPatternId,
  documentId = null
}) => {
  const { token, SIGN_PROVIDER_TYPE, accountId } = useSelector(state => ({
    token: state.auth.token,
    SIGN_PROVIDER_TYPE: state.settings.SIGN_PROVIDER_TYPE,
    accountId: state.auth.accountId
  }));
  const dispatch = useDispatch();
  const mounted = usePromise();

  const [mrkDocumentData, setMrkDocumentData] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [step, setStep] = useState(1);

  const formRef = useRef(null);

  const showModal = (...props) => {
    dispatch(actions.showModal(...props));
  };

  const updateAttachmentById = async (id) => {
    let result = null;
    try {
      result = await MrkClientServiceClient.getMrkAttachmentById(token, id);
      formRef.current.setValues({
        ...formRef.current.values, attachments: map(get(formRef, 'current.values.attachments', []), item => {
          if (get(item, 'attachment.id', null) === result.id) return { ...item, attachment: result };
          return item;
        })
      });
    } catch (error) {
      log(error);
    }
    return result;
  };

  const updateAttachment = (attachment) => {
    formRef.current.setValues({
      ...formRef.current.values, attachments: map(get(formRef, 'current.values.attachments', []), item => {
        if (get(item, 'attachment.id', null) === attachment.id) return { ...item, attachment: attachment };
        return item;
      })
    });
  };

  const signAttachment = async (attachment, keys) => {
    if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
      try {
        const cms = await signAttachmentIIT(attachment, keys?.typeSign);
        if (cms === null) return;
        const newAttachment = await MrkClientServiceClient.signAttachment(token, attachment.id, cms, keys.publicKey);
        updateAttachment(newAttachment);
      } catch (error) {
        NotificationError(error, 'signAttachment');
      }
    } else if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) {
      try {
        const cms = await signAttachmentNcaLayer(attachment);
        if (cms === null) return;
        const newAttachment = await MrkClientServiceClient.signAttachment(token, attachment.id, cms.responseObject, null);
        updateAttachment(newAttachment);
      } catch (error) {
        if (error.message !== 'action.canceled') NotificationError(error, 'signAttachment');
      }
    } else {
      log('need development');
    }
  };

  useEffect(() => {
    if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) connectNcaLayer();
    subscribe(socketId, `/ws/${accountId}`, (msg) => {
      switch (msg.event) {
        case 'ATTACHMENT_EVENT_EDITING':
          updateAttachmentById(msg.id);
          break;
        case 'ATTACHMENT_SIGNED':
          updateAttachmentById(msg.id);
          break;
        case 'ATTACHMENT_REMOVE_SIGNED':
            updateAttachmentById(msg.id);
            break;
        default:
          break;
      }
    });
    return () => {
      unsubscribe(socketId);
    };
  }, []);

  const prepareDraftDocument = async (extRespPatternId, parentId) => {
    try {
      const result = await mounted(MrkClientServiceClient.prepareDraftDocument(
        token,
        extRespPatternId,
        parentId
      ));
      setIsFetching(false);
      setMrkDocumentData(result);
    } catch (error) {
      NotificationError(error);
      setIsFetching(false);
    }
  };

  const prepareDraftAttachments = async () => {
    try {
      setIsFetching(true);
      const result = await MrkClientServiceClient.prepareDraftAttachments(
        token,
        mrkDocumentData?.document?.id
      );
      setIsFetching(false);
      setMrkDocumentData({
        ...formRef.current.values,
        atts: result,
        attachments: map(result, item => ({
          attachment: item,
          file: null
        }))
      });
      formRef.current.setValues({
        ...formRef.current.values,
        atts: result,
        attachments: map(result, item => ({
          attachment: item,
          file: null
        }))
      });
    } catch (error) {
      NotificationError(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (documentId === null) {
      if (step === 1) {
        prepareDraftDocument(extRespPatternId, parentId);
      } else {
        prepareDraftAttachments();
      }
    } else {
      const getMrkDocumentData = async (documentId) => {
        try {
          const result = await mounted(MrkClientServiceClient.getMrkDocumentData(
            token,
            documentId
          ));
          setMrkDocumentData(result);
          setIsFetching(false);
          if (result?.atts?.length > 0) setStep(2);
        } catch (error) {
          NotificationError(error);
          setIsFetching(false);
        }
      };
      getMrkDocumentData(documentId);
    }

  }, [documentId]);

  const isHasSign = (digitalSigns) => {
    return size(compact(reduce(digitalSigns, (hash, item) => {
      if (find(item.signDetails, { signInSystem: 'EXTERNAL' })) {
        hash.push(find(item.signDetails, { signInSystem: 'EXTERNAL' }));
      }
      return hash;
    }, []))) > 0;
  };

  const needSign = (requiredSign = false, isEditing = false, attachments = []) => {
    if (isEditing) return true;
    if (requiredSign === false) return false;
    if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
      return false;
    };
    let externalSigns = compact(reduce(attachments, (hash, itm) => {
      hash.push(isHasSign(itm?.attachment?.digitalSigns));
      return hash;
    }, []));
    return !(size(attachments) === size(externalSigns));
  };

  const sendDoc = async(docId, signature = null, attachmentSignature = [], publicKey = null) => {
    try {
      setIsFetching(true);
      await MrkClientServiceClient.sendDocument(token, docId, signature, attachmentSignature, publicKey);
      dispatch(getMrkDocuments());
      dispatch(actions.hideModal('MODAL_CREATE_WIZARD_DOCUMENT'));
    } catch (error) {
      NotificationError(error);
      setIsFetching(false);
    }
  };

  const handlerCreateEditDoc = async (data, inDraft = false) => {
    try {
      if (inDraft) {
        setIsFetching(true);
        await MrkClientServiceClient.createOrUpdateMrkDocument(
          token,
          data
        );
        dispatch(actions.hideModal('MODAL_CREATE_WIZARD_DOCUMENT'));
        dispatch(getMrkDocuments());
      } else {
        if (step === 1) {
          setIsFetching(true);
          const result = await MrkClientServiceClient.createOrUpdateMrkDocument(
            token,
            data
          );
          setMrkDocumentData(result);
          setIsFetching(false);
          prepareDraftAttachments();
          setStep(2);
        } else if (step === 2) {
          dispatch(actions.showModal('MODAL_SIGN_DOC', {
            disableSend: data?.document?.requiredSign,
            handleSuccess: async (withCert) => {
              setIsFetching(true);
              try {
                await MrkClientServiceClient.createOrUpdateMrkDocument(
                  token,
                  data
                );
                setIsFetching(false);
                if(withCert){
                  const infoForSing = await MrkClientServiceClient.getDocumentInfoForSing(
                    token,
                    data.document.id
                  );
                  if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
                    dispatch(actions.showModal('MODAL_FILE_SIGN', {
                      submitModal: async (cert) => {
                        const signature = await SignDataIIT(infoForSing, false);
                        const signAtts = async (atts) => {
                          const data = {};
                          for (const item of atts) {
                            const cms = await signAttachmentIIT(item);
                            if (cms !== null) data[item.id] = cms;
                          }
                          return data;
                        };
                        const attachmentSignature = await signAtts(map(data?.atts));
                        sendDoc(data.document.id, signature, attachmentSignature, null);
                      }
                    }));
                  }
                  if(SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN){
                    const s = await signDataNcaLayer(btoa(unescape(encodeURIComponent(infoForSing))));
                    sendDoc(data.document.id, s.responseObject, [], null);
                  }
                }else {
                  sendDoc(data.document.id, null, [], null);
                }
              } catch (error) {
                NotificationError(error);
                setIsFetching(false);
              }
            }
          }));
        }
      }
    } catch (error) {
      NotificationError(error);
      setIsFetching(false);
    }
  };

  const resetTemporalDocAtachments = async () => {
    try {
      confirmationAction(async () => {
        setIsFetching(true);
        await MrkClientServiceClient.resetTemporalDocAtachments(
          token,
          mrkDocumentData?.document?.id
        );
        setMrkDocumentData({
          ...formRef.current.values,
          attachments: []
        });
        formRef.current.setValues({
          ...formRef.current.values, attachments: []
        });
        setIsFetching(false);
        setStep(1);
      }, I18n.t('CreateWizardDoc.resetTemporalDocAtachments'));

    } catch (error) {
      NotificationError(error);
      setIsFetching(false);
    }
  };

  const removeSigns = async (attachment) => {
    try {
      await MrkClientServiceClient.removeSignFromAttachment(token, attachment.id);
    } catch (error) {
      log(error);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={Yup.object({
        document: Yup.object({
          name: Yup.string().required(I18n.t('form.required'))
        })
      })}
      innerRef={formRef}
      initialValues={{
        ...mrkDocumentData,
        items: sortBy(get(mrkDocumentData, 'items', []), ['order']),
        certificate: null,
        inDraft: false,
        withECP: false,
        attachments: get(mrkDocumentData, 'atts', []).map(item => ({
          attachment: item,
          file: null
        }))
      }}
      onSubmit={(values) => {
        const data = new MrkDocumentData({
          ...values,
          atts: values.attachments.map(item => item.attachment)
        });
        handlerCreateEditDoc(data, values.inDraft);
        //createOrUpdateMrkDocument(data, values.send, values.certificate, values?.withECP);
      }}
    >{({ handleSubmit, values, validateForm, setTouched, setValues }) => {
      const isEditing = size(filter(values.attachments, item => {
        return get(item, 'attachment.isEditing', false);
      })) > 0;
      return (
        <Modal
          visible={true}
          width={720}
          centered
          bodyStyle={{
            height: 'calc(100vh - 180px)',
            padding: 0,
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
          onCancel={() => dispatch(actions.hideModal('MODAL_CREATE_WIZARD_DOCUMENT'))}
          maskClosable={false}
          footer={step === 1 ? <Row type="flex" gutter={[16, 0]} justify="space-between" key="step_1">
            <Col>
              <Button
                key="draft"
                loading={isFetching}
                onClick={async () => {
                  const isValidForm = await validateForm();
                  setTouched(setNestedObjectValues(isValidForm, true), true);
                  if (isEmpty(isValidForm)) {
                    setValues({
                      ...values,
                      inDraft: true
                    });
                    handleSubmit();
                  } else {
                    notification.error({
                      key: 'form_error',
                      message: I18n.t('common.error'),
                      description: I18n.t('common.form_is_invalid')
                    });
                  }
                }}>
                {I18n.t('CreateWizardDoc.send_in_draft')}
              </Button>
            </Col>
            <Col>
              <Button
                key="next"
                type="primary"
                loading={isFetching} onClick={async () => {
                  const isValidForm = await validateForm();
                  setTouched(setNestedObjectValues(isValidForm, true), true);
                  if (isEmpty(isValidForm)) {
                    setValues({
                      ...values,
                      inDraft: false
                    });
                    handleSubmit();
                  } else {
                    notification.error({
                      key: 'form_error',
                      message: I18n.t('common.error'),
                      description: I18n.t('common.form_is_invalid')
                    });
                  }
                }}>
                {`${I18n.t('common.next')}`}
              </Button>
            </Col>
          </Row> : <Row type="flex" gutter={[16, 0]} justify="space-between" key="step_1">
            <Col>
              <Button
                key="draft"
                loading={isFetching}
                onClick={async () => {
                  const isValidForm = await validateForm();
                  setTouched(setNestedObjectValues(isValidForm, true), true);
                  if (isEmpty(isValidForm)) {
                    setValues({
                      ...values,
                      inDraft: true
                    });
                    handleSubmit();
                  } else {
                    notification.error({
                      key: 'form_error',
                      message: I18n.t('common.error'),
                      description: I18n.t('common.form_is_invalid')
                    });
                  }
                }}>
                {I18n.t('CreateWizardDoc.send_in_draft')}
              </Button>
            </Col>
            <Col>
              <Button
                key="prev"
                type="primary"
                loading={isFetching} onClick={resetTemporalDocAtachments}>
                {`${I18n.t('common.prev')}`}
              </Button>
              <Button
                key="next"
                type="primary"
                disabled={needSign(values?.document?.requiredSign, isEditing, values.attachments)}
                loading={isFetching} onClick={async () => {
                  const isValidForm = await validateForm();
                  setTouched(setNestedObjectValues(isValidForm, true), true);
                  if (isEmpty(isValidForm)) {
                    setValues({
                      ...values,
                      inDraft: false
                    });
                    handleSubmit();
                  } else {
                    notification.error({
                      key: 'form_error',
                      message: I18n.t('common.error'),
                      description: I18n.t('common.form_is_invalid')
                    });
                  }
                }}>
                {`${I18n.t('common.next')}`}
              </Button>
            </Col>
          </Row>}
          title={I18n.t('CreateWizardDoc.title')}
        >
          {isFetching && <Loader />}
          <Row style={{ borderBottom: '1px solid #f0f0f0', padding: '8px 24px' }}>
            <Typography.Text strong>{get(values, 'document.patternName', '')}</Typography.Text>
          </Row>
          <Row style={{ borderBottom: '1px solid #f0f0f0', padding: '8px 24px' }}>
            <Typography.Text style={{ color: '#000000' }} strong>{I18n.t(`CreateWizardDoc.step${step}`)}</Typography.Text>
          </Row>
          <div style={{ flex: 1 }}>
            {step === 1 && <Step1 />}
            {step === 2 && <Step2
              showModal={showModal}
              removeEcp={removeSigns}
              signAttachment={async (attachment) => {
                if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
                  showModal('MODAL_FILE_SIGN', {
                    submitModal: (result) => signAttachment(attachment, result)
                  });
                } else if (SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN) {
                  signAttachment(attachment);
                }
              }}
            />}
          </div>
        </Modal>
      );
    }}
    </Formik>
  );
};

export default CreateMrkDocument;