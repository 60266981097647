import React from 'react';
import { Modal } from 'components/Modals';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-modals';
import { notification } from 'antd';
import { NotificationError, rsaEncrypt } from 'utils/helpers';
import * as Yup from 'yup';
import { Password } from 'components/Form';
import { MrkClientServiceClient } from 'api';
import { trim } from 'lodash';

const ChangePassword = ({ hideModal, token, PASSWORD_REG_EXP }) => {
  return (
    <Formik
      initialValues={{
        oldPassword: '',
        password: '',
        confirmation: ''
      }}
      validationSchema={Yup.object({
        oldPassword: Yup.string().required(I18n.t('form.required')).matches(
          new RegExp(PASSWORD_REG_EXP),
          I18n.t('form.password_regexp')
        ),
        password: Yup.string().required(I18n.t('form.required')).matches(
          new RegExp(PASSWORD_REG_EXP),
          I18n.t('form.password_regexp')
        ),
        confirmation: Yup.string().required(I18n.t('form.required'))
          .oneOf([Yup.ref('password'), null], I18n.t('ChangePassword.password_not_match')).matches(
            new RegExp(PASSWORD_REG_EXP),
            I18n.t('form.password_regexp')
          ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await MrkClientServiceClient.changePassword(token, rsaEncrypt(trim(values.oldPassword)), rsaEncrypt(trim(values.password)), rsaEncrypt(trim(values.confirmation)));
          notification.success({
            key: 'changePassword',
            message: I18n.t('notification.change_password')
          });
          setSubmitting(false);
          hideModal('MODAL_CHANGE_PASSWORD');
        } catch (error) {
          setSubmitting(false);
          NotificationError(error, 'changePassword');
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => <Modal
        visible={true}
        centered
        maskClosable={false}
        title={I18n.t('ChangePassword.title')}
        onCancel={() => hideModal('MODAL_CHANGE_PASSWORD')}
        onOk={handleSubmit}
        okButtonProps={{ loading: isSubmitting }}
        okText={I18n.t('common.save')}
        cancelText={I18n.t('common.cancel')}
      >
        <Form layout={'horizontal'}>
          <Form.Item
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="oldPassword"
            label={I18n.t('ChangePassword.oldPassword')}
          >
            <Password name="oldPassword" />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="password"
            label={I18n.t('ChangePassword.password')}
          >
            <Password name="password" />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="confirmation"
            label={I18n.t('ChangePassword.confirmation')}
          >
            <Password name="confirmation" />
          </Form.Item>
        </Form>
      </ Modal>}
    </Formik>
  );
};

const mapStateToProps = state => ({
  token: state.auth.token,
  PASSWORD_REG_EXP: state.settings.PASSWORD_REG_EXP
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideModal: actions.hideModal
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);