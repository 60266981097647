import React from 'react';
import { Typography, Col } from 'antd';
import Info from './Info';
import { I18n } from 'react-redux-i18n';
import { map, size, get, lowerCase } from 'lodash';
import Attachment from './Attachment';
import TitleBlock from './TitleBlock';
import classnames from 'classnames';
import { getSignInSystemText } from 'utils/helpers';
import * as styles from '../right-preview.module.scss';

const MrkDocumentView = ({ selectedAttachment, mrkDocumentData, mrkAttachment }) => {
  const textSign = getSignInSystemText(get(mrkDocumentData, 'document.signInSystem', null));
  return <>
    <Col span={24}>
      <TitleBlock
        text={I18n.t('MrkDocumentView.info')}
      />
    </Col>
    <Col span={24}>
      <Info mrkDocumentData={mrkDocumentData} />
    </Col>
    {size(mrkDocumentData.atts) > 0 && <Col span={24}>
      <TitleBlock
        text={I18n.t('MrkDocumentView.attachments')}
      >
        {textSign !== '' && <Typography.Text className={classnames(styles.titleRight, styles.before_dot)}>{`${I18n.t('SignInSystem.first')} ${lowerCase(textSign)}`}</Typography.Text>}
      </TitleBlock>
    </Col>}
    {map(mrkDocumentData.atts, item => <Col span={24} key={item.id}>
      <Attachment
        attachment={item}
        onClick={() => selectedAttachment(item)}
        active={get(mrkAttachment, 'id', null) === item.id}
      />
    </Col>)}
  </>;
};

export default MrkDocumentView;