/*eslint no-throw-literal: "off"*/
import React from 'react';
import { notification } from 'antd';
import { EndUser, EndUserKeyMedia, EndUserConstants } from 'lib/euscp';
import { get, size, find, filter } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { readFile, buf2hex, getAttachmentUrl, getP7sUrl, getFileFromUrl, NotificationError, log } from 'utils/helpers';
import store from 'redux/store';
const { EndUserSignAlgo, EndUserHashAlgo, EndUserLibraryType, EndUserSignType, EU_SIGN_TYPE_PARAMETER } = EndUserConstants;

const euSignFile = new EndUser(
  null,
  EndUserLibraryType.JS);

const euSignSW = new EndUser(
  null,
  EndUserLibraryType.SW
);

let socketNcaLayer = null;
let socketNcaLayerCallback = null;

export const euSignInitialized = async (type = 'file') => {
  try {
    const state = store.getState();
    const IIT = state.settings.IIT;
    const language = state.i18n.locale;
    let euSign = null;
    switch (type) {
      case 'sw':
        euSign = euSignSW;
        break;
      default:
        euSign = euSignFile;
        break;
    }
    const result = await euSign.IsInitialized();
    if (result) return euSign;
    await euSign.Initialize({
      ...IIT,
      language,
      CAs: `${process.env.PUBLIC_URL}${IIT.CAs}`,
      CACertificates: `${process.env.PUBLIC_URL}${IIT.CACertificates}`
    });
    await euSign.SetRuntimeParameter(EU_SIGN_TYPE_PARAMETER, EndUserSignType.CAdES_T);
    return euSign;
  } catch (error) {
    throw error;
  }
};

export const getPrivateKeyIIT = async (file, password = null, type = 'file') => {
  try {
    const data = await readFile(file);
    const euSign = await euSignInitialized(type);
    if (data.file.name.endsWith('.jks')) {
      const jksKeys = await euSign.GetJKSPrivateKeys(data.data);
      return await euSign.ReadPrivateKeyBinary(
        jksKeys[0].privateKey,
        password, null, null);
    }
    return await euSign.ReadPrivateKeyBinary(
      data.data, password, null, null);
  } catch (error) {
    throw error;
  }
};

export const getPrivateKeySWIIT = async (password = null, type = 'sw') => {
  try {
    const euSign = await euSignInitialized(type);
    const keyMedias = await euSign.GetKeyMedias();
    if(keyMedias.length === 0){
      throw {
        message: I18n.t('IIT.tokenNotFound') 
      };
    }else {
      const keyMedia = new EndUserKeyMedia(keyMedias[0]);
      keyMedia.password = password;
      return await euSign.ReadPrivateKey(keyMedia, null, null);
    }
  } catch (error) {
    throw error;
  }
};

export const tokenIsSupport = async () => {
  try {
    const result = await euSignSW.GetLibraryInfo();
    if (!result.supported) {
      throw {
        message: I18n.t('IIT.notSupported')
      };
    }

    if (!result.loaded) {
      if (result.isNativeLibraryNeedUpdate) {
        throw {
          message: I18n.t('IIT.isNativeLibraryNeedUpdate'),
          url: <a download rel="noopener noreferrer" href={result.nativeLibraryInstallURL}>{I18n.t('common.download')}</a>
        };
      }
      
      if (result.isWebExtensionSupported &&
        !result.isWebExtensionInstalled) {
        throw {
          message: I18n.t('IIT.isWebExtensionInstalled'),
          url: <a download rel="noopener noreferrer" href={result.webExtensionInstallURL}>{I18n.t('common.download')}</a>
        };
      }

      throw {
        message: I18n.t('IIT.isNativeLibraryInstalled'),
        url: <a download rel="noopener noreferrer" href={result.nativeLibraryInstallURL}>{I18n.t('common.download')}</a>
      };
    }
    return result;
  } catch (error) {
    notification.info({
      key: 'sw_error',
      message: error.message,
      description: error.url,
      duration: 0
    });
    throw error;
  }
};

export const SignDataIIT = async (data, external = true, type = 'file') => {
  try {
    const euSign = await euSignInitialized(type);
    return await euSign.SignDataEx(EndUserSignAlgo.DSTU4145WithGOST34311, data, external, true, true);
  } catch (error) {
    throw error;
  }
};

export const SignDataRegIIT = async (data, external = true, type = 'file') => {
  try {
    const euSign = await euSignInitialized(type);
    return await euSign.SignDataEx(EndUserSignAlgo.DSTU4145WithGOST34311, data, external, true, true);
  } catch (error) {
    throw error;
  }
};

export const AppendSignIIT = async (data, previousSign = null, type = 'file') => {
  try {
    const euSign = await euSignInitialized(type);
    return await euSign.AppendSign(EndUserSignAlgo.DSTU4145WithGOST34311, data, previousSign, true, true);
  } catch (error) {
    throw error;
  }
};

export const VerifyDataIIT = async (sign, data = null, type = 'file') => {
  try {
    const euSign = await euSignInitialized(type);
    if (data === null) {
      return await euSign.VerifyDataInternal(sign);
    } else {
      return await euSign.VerifyData(data, sign);
    }
  } catch (error) {
    throw error;
  }
};

export const GetOwnCertificatesIIT = async(type = 'file') => {
  try {
    const euSign = await euSignInitialized(type);
    return await euSign.GetOwnCertificates();
  } catch (error) {
    throw error;
  }
};

export const HashData = async (data, base64 = false, type = 'file') => {
  try {
    const euSign = await euSignInitialized(type);
    const result = await euSign.HashData(EndUserHashAlgo.GOST34311, new TextEncoder().encode(data), base64);
    return base64 ? result : buf2hex(result.buffer);
  } catch (error) {
    throw error;
  }
};

export const signAttachmentIIT = async (attachment, type = 'file') => {
  try {
    const certificates = await GetOwnCertificatesIIT(type);
    const serial = get(find(certificates, { infoEx: { keyUsageType: 3 } }), 'infoEx.serial', null);
    if (attachment.hasDigitalSign) {
      const digitalSign = find(attachment.digitalSigns, { signProviderType: SignProviderType.UA_SIGN });
      if (digitalSign && size(filter(digitalSign.signDetails, { serialNumber: serial })) === 0) {
        const fileData = await getFileFromUrl(getAttachmentUrl(attachment, 'ORIGINAL'), 'arraybuffer');
        const cms = await getFileFromUrl(getP7sUrl(digitalSign.id), 'base64');
        return await AppendSignIIT(fileData, cms, type);
      } else if (digitalSign === undefined) {
        const fileData = await getFileFromUrl(getAttachmentUrl(attachment, 'ORIGINAL'), 'arraybuffer');
        return await SignDataIIT(fileData, false, type);
      } else {
        return null;
      }
    }
    const fileData = await getFileFromUrl(getAttachmentUrl(attachment, 'ORIGINAL'), 'arraybuffer');
    return await SignDataIIT(fileData, false, type);
  } catch (error) {
    throw {
      preconditionExceptionKey: I18n.t('common.error'),
      message: get(error, 'message', I18n.t('errors.UNKNOWN_ERROR'))
    };
  }
};

export const connectNcaLayer = () => {
  if (socketNcaLayer !== null) return socketNcaLayer;
  const state = store.getState();
  const URL = state.settings.NCA_LAYER.URL;
  socketNcaLayer = new WebSocket(URL);
  socketNcaLayer.onopen = function (event) {
    log('Connection opened');
  };

  socketNcaLayer.onclose = function (event) {
    if (event.wasClean) {
      socketNcaLayer = null;
    } else {
      NotificationError({
        preconditionExceptionKey: I18n.t('common.error'),
        message: I18n.t('errors.nca_layer_connect_errror')
      });
      socketNcaLayer = null;
    }
  };

  socketNcaLayer.onmessage = function (event) {
    var result = JSON.parse(event.data);

    if (result != null) {
      var rw = {
        code: result['code'],
        message: result['message'],
        responseObject: result['responseObject'],
        getResult: function () {
          return this.result;
        },
        getMessage: function () {
          return this.message;
        },
        getResponseObject: function () {
          return this.responseObject;
        },
        getCode: function () {
          return this.code;
        }
      };
      if (socketNcaLayerCallback != null) {
        socketNcaLayerCallback(rw);
      }
    }
  };
};

const SendNcaLayer = message => {
  if (socketNcaLayer === null) return;
  socketNcaLayer.send(message);
};

export const signDataNcaLayer = (data) => {
  connectNcaLayer();
  return new Promise((resolve, reject) => {
    const createCMSSignatureFromBase64 = {
      'module': 'kz.gov.pki.knca.commonUtils',
      'method': 'createCMSSignatureFromBase64',
      'args': ['PKCS12', 'SIGNATURE', data, true]
    };
    socketNcaLayerCallback = (e) => {
      if (e.code !== '200') {
        reject(e);
      } else {
        resolve(e);
      }
    };
    SendNcaLayer(JSON.stringify(createCMSSignatureFromBase64));
  });
};

export const getKeyInfoNcaLayer = () => {
  connectNcaLayer();
  return new Promise((resolve, reject) => {
    const request = {
      'module': 'kz.gov.pki.knca.commonUtils',
      'method': 'getKeyInfo',
      'args': ['PKCS12']
    };
    socketNcaLayerCallback = (e) => {
      if (e.code !== '200') {
        reject(e);
      } else {
        resolve(e);
      }
    };
    SendNcaLayer(JSON.stringify(request));
  });
};


export const signAttachmentNcaLayer = async (attachment) => {
  connectNcaLayer();
  if (attachment.hasDigitalSign) {
    const digitalSign = find(attachment.digitalSigns, { signProviderType: SignProviderType.KAZ_SIGN });
    if (digitalSign) {
      const cms = await getFileFromUrl(getP7sUrl(digitalSign.id), 'base64');
      return await signDataNcaLayer(cms);
    } else if (digitalSign === undefined) {
      const fileData = await getFileFromUrl(getAttachmentUrl(attachment, 'ORIGINAL'), 'base64');
      return await signDataNcaLayer(fileData);
    } else {
      return null;
    }
  }
  const fileData = await getFileFromUrl(getAttachmentUrl(attachment, 'ORIGINAL'), 'base64');
  return await signDataNcaLayer(fileData);
};
