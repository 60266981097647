import React from 'react';
import { Modal } from 'components/Modals';
import { Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-modals';
import { notification, Typography } from 'antd';
import { NotificationError } from 'utils/helpers';
import * as Yup from 'yup';
import { MrkClientServiceClient } from 'api';

const ResetPassword = ({ hideModal, token }) => {
  return (
    <Formik
      initialValues={{
        login: ''
      }}
      validationSchema={Yup.object({
        login: Yup.string().required(I18n.t('form.required'))
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await MrkClientServiceClient.requestResetPassword(token, values.login);
          notification.success({
            key: 'resetPassword',
            message: I18n.t('notification.reset_password')
          });
          setSubmitting(false);
          hideModal('MODAL_RESET_PASSWORD');
        } catch (error) {
          setSubmitting(false);
          NotificationError(error, 'resetPassword');
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => <Modal
        visible={true}
        centered
        maskClosable={false}
        title={I18n.t('ResetPassword.title')}
        onCancel={() => hideModal('MODAL_RESET_PASSWORD')}
        onOk={handleSubmit}
        okButtonProps={{ loading: isSubmitting }}
        okText={I18n.t('common.send')}
        cancelText={I18n.t('common.cancel')}
      >
        <Form layout={'horizontal'}>
          <Typography.Paragraph>
            {I18n.t('ResetPassword.description')}
          </Typography.Paragraph>
          <Form.Item name="login">
            <Input 
              name="login" 
              placeholder={I18n.t('ResetPassword.enter_login')}
            />
          </Form.Item>
        </Form>
      </ Modal>}
    </Formik>
  );
};

const mapStateToProps = state => ({
  token: state.auth.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideModal: actions.hideModal
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);