import React from 'react';
import moment from 'moment';
import { DatePicker } from 'formik-antd';
import { isDataTime } from 'utils/helpers';
import { useField } from 'formik';

const DatePickerCustom = ({ useString = false, ...props }) => {
  const [field, , helpers] = useField(props);
  return <DatePicker
    name={field.name}
    {...props}
    value={isDataTime(field.value) ? moment(useString ? Number(field.value) : field.value) : null}
    onChange={e => helpers.setValue(e === null ? null : useString ? String(e.valueOf()) : e.valueOf())}
  />;
};

export default DatePickerCustom;