import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import Loader from 'components/Loader';
import { Redirect } from 'react-router-dom';
import { NotificationError, getQueryParam } from 'utils/helpers';
import { MrkClientServiceClient } from 'api';
import { connect } from 'react-redux';

const ConfirmationEmail = ({ locale }) => {
  const [ request, setRequest ] = useState(false);

  const confirm = async () => {
    try {
      await MrkClientServiceClient.confirmRegistration(getQueryParam('login'), getQueryParam('code'), getQueryParam('email'), locale);
      notification.success({
        message: I18n.t('common.info'),
        description: I18n.t('notification.confirm_registration', { email: getQueryParam('email') }),
        duration: 5
      });
    } catch (error) {
      NotificationError(error, 'confirmRegistration');
    }
    setRequest(true);
  };

  useEffect(() => {
    confirm();
  }, []);

  if (request){
    return <Redirect to="/" />;
  }

  return <Loader />;
};

const mapStateToProps = state => ({
  locale: state.i18n.locale
});

export default connect(mapStateToProps, null)(ConfirmationEmail);