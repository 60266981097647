import React from 'react';
import { notification, Button } from 'antd';
import { logout } from './auth';
import { getMrkDocuments } from './mrkDocuments';
import { showPreviewDocument } from './mrkDocument';
import store, { history } from '../store';
import { sendMessage } from 'api';
import { log } from 'utils/helpers';
import { I18n } from 'react-redux-i18n';

export const socket = ({ event, ...msg }) => {
  const state = store.getState();
  const dispatch = store.dispatch;
  const { mrkDocuments: { page, mrkDocumentType }, router: { location: { pathname } } } = state;
  try {
    switch (event) {
      case 'notification':
        notification.open(msg);
        break;
      case 'reload':
        window.location.reload();
        break;
      case 'logout':
        dispatch(logout());
        break;
      case 'logoutId':
        if (state.auth.id === msg.auth.id) dispatch(logout());
        break;
      case 'getAllUsers':
        sendMessage('/ws/notification', {
          event: 'session',
          auth: state.auth
        });
        break;
      case 'session':
        log('session', msg.auth);
        break;
      case 'RECEIVE_DOCUMENT':
        if (pathname === '/mrkDocuments' && page === 1 && mrkDocumentType === MrkDocumentType.INPUT) dispatch(getMrkDocuments(page, false));
        notification.info({
          key: 'doc_not_open',
          duration: 0,
          message: I18n.t('common.info'),
          description: I18n.t('notification.new_document'),
          btn: <Button type="primary" size="small" onClick={() => {
            notification.close('doc_not_open');
            if (pathname !== '/mrkDocuments') {
              history.push('/mrkDocuments');
            }
            dispatch(showPreviewDocument({
              id: msg.documentId
            }));
          }}>
            {I18n.t('notification.button_open')}
          </Button>
        });
        break;
      case 'CANCEL_DOCUMENT':
        if (pathname === '/mrkDocuments') dispatch(getMrkDocuments(page, false));
        break;
      case 'ATTACHMENT_PREVIEW_EVENT':
        notification.close(msg.id);
        break;
      default:
        log(`SOCKET_EVENT ${event}`, msg);
        break;
    }
  } catch (error) {
    log(error);
  }
};