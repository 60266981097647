import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'react-redux-modals';
import { usePromise } from 'react-use';
import { Modal } from 'components/Modals';
import { Table } from 'antd';
import { NotificationError } from 'utils/helpers';
import Loader from 'components/Loader';
import { MrkClientServiceClient } from 'api';

const SelectPatternAttachments = ({ handleSuccess = () => { }, documentId = null, patternId = null }) => {
  const { token } = useSelector(state => ({
    token: state.auth.token
  }));
  const dispatch = useDispatch();
  const mounted = usePromise();

  const [isFetching, setIsFetching] = useState(true);
  const [patternAtts, setPatternAtts] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const getAllPatternAttachmentTemplates = async () => {
    try {
      setIsFetching(true);
      const result = await mounted(MrkClientServiceClient.getAllPatternAttachmentTemplates(token, patternId, new KazFilter({
        position: 0,
        countFilter: 999,
        orders: [],
        items: []
      })));
      setPatternAtts(result);
      setIsFetching(false);
    } catch (error) {
      NotificationError(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getAllPatternAttachmentTemplates();
  }, []);

  const columns = useMemo(() => [
    {
      title: I18n.t('SelectPatternAttachments.oName'),
      id: 'oName',
      render: ({ oName }) => oName,
    }
  ], []);

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys) => setSelectedIds(selectedRowKeys)
  };

  const onOk = useCallback(async () => {
    try {
      const result = await MrkClientServiceClient.createAttachmentFromTemplate(token, documentId, selectedIds);
      handleSuccess(result);
      dispatch(actions.hideModal('MODAL_SELECT_PATTERN_ATTS'));
    } catch (error) {
      NotificationError(error);
    }
  }, [selectedIds]);

  return (
    <Modal
      visible={true}
      width={720}
      centered
      bodyStyle={{
        height: 'calc(100vh - 180px)',
        overflowY: 'hidden'
      }}
      maskClosable={false}
      title={I18n.t('SelectPatternAttachments.title')}
      onCancel={() => dispatch(actions.hideModal('MODAL_SELECT_PATTERN_ATTS'))}
      onOk={onOk}
    >
      {isFetching && <Loader />}
      <Table
        rowSelection={rowSelection}
        rowKey={'id'}
        size={'small'}
        pagination={false}
        style={{ height: '100%' }}
        columns={columns}
        dataSource={patternAtts}
      />
    </Modal>
  );
};
export default SelectPatternAttachments;