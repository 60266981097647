import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HeaderLogo } from 'components/LayoutApp';
import { getMrkDocuments, changeMrkDocumentType, changeTextSearch } from 'redux/actions/mrkDocuments';
import MenuFilters from 'components/MenuFilters';
import { Row, Col, Button, Input } from 'antd';
import { I18n } from 'react-redux-i18n';
import { actions } from 'react-redux-modals';
import { keys, reverse, filter, get } from 'lodash';
import * as styles from './header.module.scss';
const HeaderDocs = ({ showModal, isFetching, getMrkDocuments, changeMrkDocumentType, mrkDocumentType, changeTextSearch, searchText }) => <HeaderLogo >
  <Row type="flex" justify="space-between" className={styles.header}>
    <Col>
      <MenuFilters
        prefix="MrkDocumentType"
        data={filter(keys(MrkDocumentType), itm => itm !== 'TEMPORAL')}
        active={keys(MrkDocumentType)[mrkDocumentType]}
        onClick={type => changeMrkDocumentType(get(reverse(MrkDocumentType), type, MrkDocumentType.INPUT))}
      />
    </Col>
    <Col>
    <Row type="flex" justify="space-between" align="middle" gutter={[16, 0]}>
        <Col>
          <Button type="primary"
            onClick={() => {
              showModal('MODAL_SELECT_PATTERN', {
                handleSuccess: (pattern) => {
                  showModal('MODAL_CREATE_WIZARD_DOCUMENT', {
                    parentId: null,
                    extRespPatternId: pattern?.id
                  });
                }
              });
            }}>
            {I18n.t('MrkDocuments.create')}
          </Button>
        </Col>
        <Col className={styles.search}>
          <Input.Search
            value={searchText}
            onChange={e => changeTextSearch(e.target.value)}
            onSearch={() => getMrkDocuments()}
            placeholder={I18n.t('MrkDocuments.search')}
            disabled={isFetching}
          />
        </Col>
      </Row>
    </Col>
  </Row>
</HeaderLogo>;

const mapStateToProps = state => ({
  searchText: state.mrkDocuments.searchText,
  mrkDocumentType: state.mrkDocuments.mrkDocumentType,
  isFetching: state.mrkDocuments.isFetching
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal: actions.showModal,
      getMrkDocuments,
      changeMrkDocumentType,
      changeTextSearch
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(HeaderDocs);