import React from 'react';
import { Form, Input, DatePicker } from 'formik-antd';
import { FieldArray, useFormikContext } from 'formik';
import { I18n } from 'react-redux-i18n';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form as AForm } from 'antd';
import { map, get, filter, indexOf } from 'lodash';
import moment from 'moment';
import { Password } from 'components/Form';
import { mrkContactInfo } from 'utils/structures';
import * as styles from '../styles.module.scss';

const Client = ({ formItemProps, prefix = '', MIDDLE_NAME_REQUIRED = false, login = true, password = false }) => {
  const { values, setFieldValue, errors, submitCount } = useFormikContext();

  const isConfirmedField = (name) => {
    const confirmedFields = get(values, `${prefix}confirmedFields`, []);
    return indexOf(confirmedFields, name) !== -1;
  };

  return <>
    {login && <Form.Item name={`${prefix}login`}
      {...formItemProps}
      required={true}
      label={I18n.t('MrkClient.login')}
    >
      <Input name={`${prefix}login`}
        onKeyPress={e => {
          const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
          const regex = new RegExp('^[a-zA-Z0-9@.]+$');
          if (!regex.test(key)) {
            e.preventDefault();
            return false;
          }
        }} placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>}
    {password && <>
      <Form.Item name={'password'}
        {...formItemProps}
        required={true}
        label={I18n.t('SignIn.password')}
      >
        <Password
          name={'password'}
          placeholder={I18n.t('form.enter_value')}
          size={'large'}
        />
      </Form.Item>
      <Form.Item name={'confirmationPassword'}
        {...formItemProps}
        required={true}
        label={I18n.t('SignIn.confirmationPassword')}
      >
        <Password
          name={'confirmationPassword'}
          placeholder={I18n.t('form.enter_value')}
          size={'large'}
        />
      </Form.Item>
    </>}
    <Form.Item name={`${prefix}lastName`}
      {...formItemProps}
      required={true}
      label={I18n.t('MrkClient.lastName')}
    >
      <Input name={`${prefix}lastName`}
        disabled={isConfirmedField('lastName')} placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>
    <Form.Item name={`${prefix}firstName`}
      {...formItemProps}
      required={true}
      label={I18n.t('MrkClient.firstName')}
    >
      <Input name={`${prefix}firstName`} 
        disabled={isConfirmedField('firstName')}  placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>
    <Form.Item name={`${prefix}middleName`}
      {...formItemProps}
      required={MIDDLE_NAME_REQUIRED}
      label={I18n.t('MrkClient.middleName')}
    >
      <Input name={`${prefix}middleName`} 
        disabled={isConfirmedField('middleName')}  placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>
    <Form.Item name={`${prefix}birthDate`}
      {...formItemProps}
      label={I18n.t('MrkClient.birthDate')}
    >
      <DatePicker
        size={'large'}
        name={`${prefix}birthDate`}
        placeholder={I18n.t('form.select_date')}
        format={'DD.MM.YYYY'}
        showTime={false}
        value={get(values, `${prefix}birthDate`, -1) === -1 || get(values, `${prefix}birthDate`, -1) === null ? null : moment(get(values, `${prefix}birthDate`, -1), 'x')}
        onChange={(e) => setFieldValue(`${prefix}birthDate`, e === null ? null : Number(e.format('x')))}
        disabled={isConfirmedField('birthDate')} 
      />
    </Form.Item>
    <Form.Item name={`${prefix}position`}
      {...formItemProps}
      required={true}
      label={I18n.t('MrkClient.position')}
    >
      <Input name={`${prefix}position`} disabled={isConfirmedField('position')} placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item>
    <FieldArray
      name={`${prefix}contacts`}
      render={arrayHelpers => (
        <AForm.Item
          label={I18n.t('MrkClient.contacts_email')}
          required={true}
          {...formItemProps}
        >
          {map(get(values, `${prefix}contacts`, []), (item, index) => (
            item.cType === MrkContactType.EMAIL ? <Form.Item
              name={`${prefix}contacts.${index}.cValue`}
              key={index}
            >
              <div className={styles.contact_info}>
                <Input fast={true} name={`${prefix}contacts.${index}.cValue`} placeholder={I18n.t('form.enter_value')} size={'large'} />
                <CloseCircleOutlined className={styles.remove} onClick={() => arrayHelpers.remove(index)} />
              </div>
            </Form.Item> : null
          ))}
          <Row>
            <Col span={24}>
              <Button icon={<PlusOutlined />} type={submitCount > 0 && get(errors, `${prefix}contacts`, false) && filter(get(values, `${prefix}contacts`, []), {
                cType: MrkContactType.EMAIL
              }).length === 0 ? 'danger' : 'ghost'} onClick={() => arrayHelpers.push(mrkContactInfo({
                cType: MrkContactType.EMAIL
              }))}>{I18n.t('common.add_more')}</Button>
            </Col>
          </Row>
        </AForm.Item>
      )}
    />
    <FieldArray
      name={`${prefix}contacts`}
      render={arrayHelpers => (<AForm.Item
        label={I18n.t('MrkClient.contacts_phone')}
        required={true}
        {...formItemProps}
      >
        {map(get(values, `${prefix}contacts`, []), (item, index) => {
          return item.cType === MrkContactType.PHONE ? <Form.Item
            name={`${prefix}contacts.${index}.cValue`}
            key={index}
          >
            <div className={styles.contact_info}>
              <Input fast={true} name={`${prefix}contacts.${index}.cValue`} placeholder={I18n.t('form.enter_value')} size={'large'} />
              <CloseCircleOutlined className={styles.remove} onClick={() => arrayHelpers.remove(index)} />
            </div>
          </Form.Item> : null;
        })}
        <Row>
          <Col span={24}>
            <Button icon={<PlusOutlined />} type={submitCount > 0 && get(errors, `${prefix}contacts`, false) && filter(get(values, `${prefix}contacts`, []), {
              cType: MrkContactType.PHONE
            }).length === 0 ? 'danger' : 'ghost'} onClick={() => arrayHelpers.push(mrkContactInfo({
              cType: MrkContactType.PHONE
            }))}>{I18n.t('common.add_more')}</Button>
          </Col>
        </Row>
      </AForm.Item>)}
    />
    < Form.Item name={`${prefix}inn`}
      {...formItemProps}
      //required={true}
      label={I18n.t('MrkClient.inn')}
    >
      <Input name={`${prefix}inn`} disabled={isConfirmedField('inn')} placeholder={I18n.t('form.enter_value')} size={'large'} />
    </Form.Item >
  </>;
};

export default Client;