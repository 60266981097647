import React from 'react';
import { PaperClipOutlined } from '@ant-design/icons';
import { Typography, Row, Col, Button, Upload, notification } from 'antd';
import { Form, Input, DatePicker } from 'formik-antd';
import { useFormikContext, FieldArray } from 'formik';
import { I18n } from 'react-redux-i18n';
import { get, size, map, compact } from 'lodash';
import { UploadFile } from 'components/Attachment';
import Attachment from 'components/RightMrkDocumentInfo/components/Attachment';
import ContentItemTemplate from 'components/ContentItemTemplate';
import Scrollbar from 'components/Scrollbar';
import moment from 'moment';
import { confirmationAction, getFileExt } from 'utils/helpers';
import * as styles from '../../index.module.scss';
const FormData = ({ showModal, removeEcp, signAttachment, ALLOWED_ATTACHMENT_UPLOAD }) => {
  const { values, setFieldValue } = useFormikContext();
  const formItemProps = {
    labelAlign: 'left',
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    colon: false,
    className: styles.form_item
  };
  return (
    <Scrollbar>
      <div style={{ padding: '12px 24px' }}>
        <Row>
          <Typography.Text strong>{get(values, 'document.patternName', '')}</Typography.Text>
        </Row>
        <Row style={{ marginTop: 12 }} >
          <Form layout={'horizontal'} style={{ width: '100%' }}>
            <Form.Item
              {...formItemProps}
              name="document.name"
              required
              label={<span>{I18n.t('MrkDocument.name')}</span>}
            >
              <Input.TextArea name="document.name" rows={3} placeholder={I18n.t('form.enter_value')} />
            </Form.Item>
            <Form.Item
              {...formItemProps}
              name="document.documentNumber"
              label={<span>{I18n.t('MrkDocument.documentNumber')}</span>}
            >
              <Input name="document.documentNumber" placeholder={I18n.t('form.enter_value')} />
            </Form.Item>
            <Form.Item
              {...formItemProps}
              name="document.documentRegDate"
              label={<span>{I18n.t('MrkDocument.documentRegDate')}</span>}
            >
              <DatePicker
                name={'document.documentRegDate'}
                placeholder={I18n.t('form.select_date')}
                format={'DD.MM.YYYY HH:mm'}
                showTime={{ format: 'HH:mm' }}
                value={get(values, 'document.documentRegDate', -1) === -1 ? null : moment(get(values, 'document.documentRegDate', null))}
                onChange={(e) => setFieldValue('document.documentRegDate', e === null ? -1 : e.valueOf())}
              />
            </Form.Item>
            {map(values.items, (item, index) => item.cVisible ? <ContentItemTemplate
              key={index}
              item={item}
              name={`items.${index}`}
              patternId={get(values, 'document.patternId', null)}
              formItemProps={formItemProps}
            /> : null)}
            <FieldArray
              name="attachments"
              render={arrayHelpers => (
                <Form.Item
                  {...formItemProps}
                  hasFeedback={false}
                  name={`attachments`}
                  label={<span>{I18n.t('common.attachments')}</span>}
                >
                  <Row gutter={[16, 16]}>
                    {map(values.attachments, (item, index) => <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      key={index}
                    >
                      {item.attachment === null ?
                        <UploadFile
                          file={item.file}
                          setAttachment={(el) => arrayHelpers.replace(index, {
                            attachment: el,
                            file: null
                          })}
                          removeAttachment={() => {
                            arrayHelpers.remove(index);
                          }}
                          documentId={values.document.id}
                        /> :
                        <Attachment
                          onClick={() => showModal('MODAL_ATTACHMENT_EDIT', {
                            mrkAttachment: item.attachment
                          })}
                          removeAttachment={item.attachment.isEditing ? null : (e) => {
                            e.stopPropagation();
                            confirmationAction(() => { arrayHelpers.remove(index); }, I18n.t('confirmation.remove_attachment', { name: item.attachment.fileName }));
                          }}
                          removeEcp={(e) => {
                            e.stopPropagation();
                            confirmationAction(() => removeEcp(item.attachment, index), I18n.t('confirmation.remove_ecp', { name: item.attachment.fileName }));
                          }}
                          attachment={item.attachment}
                          signAttachment={!item.attachment.isEditing ? () => signAttachment(item.attachment) : null}
                          menu={compact([
                            !item.attachment.isEditing ? {
                              title: I18n.t('common.sign'),
                              onClick: () => signAttachment(item.attachment)
                            } : null,
                            !item.attachment.isEditing ? {
                              title: I18n.t('common.remove'),
                              onClick: () => confirmationAction(() => arrayHelpers.remove(index), I18n.t('confirmation.remove_attachment', { name: item.attachment.fileName }))
                            } : null,
                            size(item.attachment.digitalSigns) > 0 ? {
                              title: I18n.t('Attachment.remove_ecp'),
                              onClick: () => confirmationAction(() => removeEcp(item.attachment, index), I18n.t('confirmation.remove_ecp_and_edit', { name: item.attachment.fileName }))
                            } : null
                          ])}
                        />
                      }
                    </Col>)}
                    <Col span={24}>
                      <Upload 
                        fileList={[]} 
                        multiple={true}
                        accept={ALLOWED_ATTACHMENT_UPLOAD}
                        beforeUpload={(file) => {
                          if (ALLOWED_ATTACHMENT_UPLOAD.split(',').findIndex(ext => ext === `.${getFileExt(file.name)}`) !== -1) {
                            arrayHelpers.push({
                              attachment: null,
                              file
                            });
                          } else {
                            notification.error({
                              key: 'error_allowed',
                              message: I18n.t('common.error'),
                              description: <>
                                {I18n.t('notification.error_allowed_attachment')}<br/>
                                {ALLOWED_ATTACHMENT_UPLOAD}
                              </>,
                              duration: 6
                            });
                          }
                          return false;
                        }}>
                        <Button icon={<PaperClipOutlined />}>{I18n.t('common.add_file')}</Button>
                      </Upload>
                    </Col>
                  </Row>
                </Form.Item>
              )}
            />
          </Form>
        </Row>
      </div>
    </Scrollbar>
  );
};

export default FormData;