import React, { useEffect, useState } from 'react';
import { Modal } from 'components/Modals';
import { Form, Input, Radio } from 'formik-antd';
import { Formik } from 'formik';
import { PaperClipOutlined } from '@ant-design/icons';
import { Upload, Button, Form as AForm, notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-modals';
import { get } from 'lodash';
import { log } from 'utils/helpers';
import { GetOwnCertificatesIIT, getPrivateKeyIIT, tokenIsSupport, getPrivateKeySWIIT } from 'utils/sign';
import { SIGN_ACCEPT } from 'constants/general';
import * as Yup from 'yup';

const GetFileSign = ({ hideModal, submitModal, SIGN_PROVIDER_TYPE }) => {
  const [tokenSupport, setTokenSupport] = useState(true);

  const isSupportFunc = async () => {
    try {
      await tokenIsSupport();
      setTokenSupport(false);
    } catch (error) {
      setTokenSupport(true);
    }  
  };

  useEffect(()=>{
    isSupportFunc();
  }, []);

  return (
    <Formik
      initialValues={{
        type: 'file',
        file: null,
        password: '',
        fileList: []
      }}
      validationSchema={Yup.object({
        file: Yup.mixed().when('type', {
          is: val => {
            return val === 'file';
          },
          then: Yup.string().nullable().required(I18n.t('form.required')),
          otherwise: Yup.string().nullable()
        }),
        password: Yup.string().required(I18n.t('form.required'))
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          if (SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) {
            switch (values.type) {
              case 'sw':
                await getPrivateKeySWIIT(values.password);
                break;        
              default:
                await getPrivateKeyIIT(values.file, values.password);
                break;
            }
          }
          const certs = await GetOwnCertificatesIIT(values.type);
          submitModal({
            publicKey: btoa(String.fromCharCode.apply(null, certs[0].data)),
            typeSign: values.type
          });
          setSubmitting(false);
          hideModal('MODAL_FILE_SIGN');
        } catch (error) {
          log('getCertificatFromFile', error);
          notification.error({
            key: 'getCertificatFromFile',
            message: I18n.t('common.error'),
            description: get(error, 'message', I18n.t('errors.UNKNOWN_ERROR'))
          });
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, setValues, values, submitCount, isSubmitting, errors }) => <Modal
        visible={true}
        centered
        maskClosable={false}
        title={I18n.t('GetFileSign.title')}
        onCancel={() => hideModal('MODAL_FILE_SIGN')}
        onOk={handleSubmit}
        okButtonProps={{ loading: isSubmitting }}
        okText={I18n.t('common.next')}
        cancelText={I18n.t('common.cancel')}
      >
        <Form layout={'horizontal'} autoComplete={'new-password'}>
          <AForm.Item
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label={I18n.t('GetFileSign.type')}
          >
            <Radio.Group name="type">
              <Radio value={'file'}>{I18n.t('GetFileSign.file_type')}</Radio>
              <Radio value={'sw'} disabled={tokenSupport}>{I18n.t('GetFileSign.token_type')}</Radio>
            </Radio.Group>
          </AForm.Item>

          {values.type === 'file' && <AForm.Item
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label={I18n.t('GetFileSign.file')}
          >
            <Upload fileList={values.fileList} multiple={false}
              accept={SIGN_ACCEPT}
              onRemove={() => setValues({ ...values, fileList: [], file: null })}
              beforeUpload={(file, fileList) => {
                setValues({ ...values, fileList: fileList, file: file });
                return false;
              }}>
              <Button type={submitCount > 0 && errors.file ? 'danger' : 'default'} icon={<PaperClipOutlined />}>{I18n.t('common.add_file')}</Button>
            </Upload>
          </AForm.Item>}
          <Form.Item
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="password"
            label={I18n.t('GetFileSign.password')}

          >
            <Input.Password autoComplete="false" name="password" />
          </Form.Item>
        </Form>
      </ Modal>}
    </Formik>
  );
};

const mapStateToProps = state => ({
  token: state.auth.token,
  SIGN_PROVIDER_TYPE: state.settings.SIGN_PROVIDER_TYPE
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideModal: actions.hideModal
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(GetFileSign);