import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Select } from 'formik-antd';
import { get, find, map, debounce } from 'lodash';
import { useFormikContext } from 'formik';
import { MrkClientServiceClient } from 'api';
import { getHbValue, NotificationError } from 'utils/helpers';
import { I18n } from 'react-redux-i18n';
import { useMountedState, useFirstMountState } from 'react-use';

const HandBook = ({ token, locale, name, disabled = false, settingLayout }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const [hbValues, setHbValues] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const isMounted = useMountedState();
  const isFirstMount = useFirstMountState();

  const column = get(values, `${name}.content.value.hbValue.column`, null);

  useEffect(() => {
    if (isFirstMount) getHandBookValues(search, false);
  }, []);

  const getHandBookValues = async (text, more = false) => {
    if (get(values, `${name}.content.handBookId`, null) === null) return;
    if (isMounted()) setLoading(true);
    const searchFilterItem = text !== '' ? [new FilterItem({
      field: 'rValue',
      value: text,
      condition: FilterCondition.CONTAIN,
      fType: FilterFieldType.STRING
    }), new FilterItem({
      field: 'rColumnId',
      value: column.id,
      condition: FilterCondition.EQUAL,
      fType: FilterFieldType.STRING
    })] : [];
    const filter = new KazFilter({
      position: more ? hbValues.length : 0,
      countFilter: 15,
      orders: [],
      items: [...searchFilterItem]
    });
    try {
      const result = await MrkClientServiceClient.getAllHandBookRows(token, get(values, `${name}.content.handBookId`, null), filter);
      if (isMounted()) {
        setHbValues(more ? [...hbValues, ...result] : [...result]);
        setSearch(text);
        setLoading(false);
      }
    } catch (error) {
      NotificationError(error, 'getAllUsers');
      if (isMounted()) setLoading(false);
    }
  };
  const getHandBookValuesDebounce = debounce(getHandBookValues, 800);

  const getPrefix = () => {
    switch (column.columnType) {
      case HBColumnType.NUMBER:
      case HBColumnType.GLOBAL_TEXT:
        return `value.any`;
      case HBColumnType.USER_CHOICE:
        return `user.id`;
      default:
        return `value.${locale}`;
    }
  };

  const formName = getPrefix();
  const touchedField = get(touched, `${name}.content.value.hbValue.row.row.values.${column.id}.${formName}`, false);
  return <Form.Item
    {...settingLayout}
    hasFeedback={false}
    name={`${name}.content.value.hbValue.row.row.values.${column.id}.${formName}`}
    validateStatus={touchedField ? get(errors, `${name}.content.value.hbValue.row.row.values.${column.id}.${formName}`, null) === null ? null : 'error' : null}
    help={touchedField ? get(errors, `${name}.content.value.hbValue.row.row.values.${column.id}.${formName}`, null) : null}
    validate={value => {
      if (get(values, `${name}.cRequired`, false) && value === null) return I18n.t('form.required');
      return null;
    }}
  >
    <Select
      loading={loading}
      name={`${name}.content.value.hbValue.row.values.${column.id}.${formName}`}
      showSearch
      allowClear={getHbValue(get(values, `${name}.content.value.hbValue.row.values.${column.id}.value`, '')) !== ''}
      value={getHbValue(get(values, `${name}.content.value.hbValue.row.values.${column.id}.value`, null), locale)}
      onChange={(e) => setFieldValue(`${name}.content.value.hbValue.row`, e ? find(hbValues, { id: e }) : null)}
      onPopupScroll={e => {
        e.persist();
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          getHandBookValues(search, true);
        }
      }}
      filterOption={false}
      onSearch={getHandBookValuesDebounce}
      disabled={disabled}
    >
      {map(hbValues).map((itm, index) => <Select.Option disabled={disabled} key={index} value={itm.id}>{getHbValue(get(itm, `values.${column.id}.value`), locale)}</Select.Option>)}
    </Select>
  </Form.Item >;
};

const mapStateToProps = state => ({
  token: state.auth.token,
  locale: state.i18n.locale
});
export default connect(mapStateToProps)(HandBook);