import { NotificationError } from 'utils/helpers';
import { getMrkDocuments } from 'redux/actions/mrkDocuments';
import { actions } from 'react-redux-modals';
import { notification } from 'antd';
import { isEmpty, get, find } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { SignDataIIT, signAttachmentIIT, signDataNcaLayer } from 'utils/sign';

export const GET_DOCUMENT_PATTERNS_REQUEST = 'MODAL_CREATE_MRK_DOCUMENT/GET_DOCUMENT_PATTERNS_REQUEST';
export const GET_DOCUMENT_PATTERNS_SUCCESS = 'MODAL_CREATE_MRK_DOCUMENT/GET_DOCUMENT_PATTERNS_SUCCESS';
export const GET_DOCUMENT_PATTERNS_FAILURE = 'MODAL_CREATE_MRK_DOCUMENT/GET_DOCUMENT_PATTERNS_FAILURE';

export const getAllDocumentPatterns = (extRespPatternId, parentId) => {
  return async (dispatch, getState, api) => {
    dispatch({ type: GET_DOCUMENT_PATTERNS_REQUEST });
    try {
      const {
        auth: { token }
      } = getState();
      const filter = new KazFilter({
        position: 0,
        countFilter: 999,
        orders: [],
        items: isEmpty(extRespPatternId) ? [] : [new FilterItem({
          field: 'id',
          value: extRespPatternId,
          condition: FilterCondition.CONTAIN,
          fType: FilterFieldType.STRING
        })]
      });
      const result = await api.MrkClientServiceClient.getAllDocumentPatterns(
        token,
        filter
      );
      dispatch({
        type: GET_DOCUMENT_PATTERNS_SUCCESS,
        payload: result
      });
      if (!isEmpty(extRespPatternId)) {
        dispatch(setDocumentPattern(find(result, { id: extRespPatternId })));
        dispatch(prepareDraftDocument(extRespPatternId, parentId));
      }
    } catch (error) {
      NotificationError(error, 'getAllDocumentPatterns');
      dispatch({ type: GET_DOCUMENT_PATTERNS_FAILURE });
    }
  };
};

export const SELECTED_DOCUMENT_PATTERN = 'MODAL_CREATE_MRK_DOCUMENT/SELECTED_DOCUMENT_PATTERN';

export const setDocumentPattern = documentPattern => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_DOCUMENT_PATTERN,
      payload: documentPattern
    });
  };
};

export const PREPARE_MRK_DOCUMENT_REQUEST = 'MODAL_CREATE_MRK_DOCUMENT/PREPARE_MRK_DOCUMENT_REQUEST';
export const PREPARE_MRK_DOCUMENT_SUCCESS = 'MODAL_CREATE_MRK_DOCUMENT/PREPARE_MRK_DOCUMENT_SUCCESS';
export const PREPARE_MRK_DOCUMENT_FAILURE = 'MODAL_CREATE_MRK_DOCUMENT/PREPARE_MRK_DOCUMENT_FAILURE';

export const prepareDraftDocument = (id, parentId = null) => {
  return async (dispatch, getState, api) => {
    dispatch({ type: PREPARE_MRK_DOCUMENT_REQUEST });
    try {
      const {
        auth: { token }
      } = getState();
      const result = await api.MrkClientServiceClient.prepareDraftDocument(
        token,
        id,
        parentId
      );
      dispatch({
        type: PREPARE_MRK_DOCUMENT_SUCCESS,
        payload: new MrkDocumentData({
          ...result,
          document: new MrkDocument({ ...result.document, parentId: parentId })
        })
      });
    } catch (error) {
      NotificationError(error, 'prepareDraftDocument');
      dispatch({ type: PREPARE_MRK_DOCUMENT_FAILURE });
    }
  };
};

export const EDIT_MRK_DOCUMENT_REQUEST = 'MODAL_CREATE_MRK_DOCUMENT/EDIT_MRK_DOCUMENT_REQUEST';
export const EDIT_MRK_DOCUMENT_SUCCESS = 'MODAL_CREATE_MRK_DOCUMENT/EDIT_MRK_DOCUMENT_SUCCESS';
export const EDIT_MRK_DOCUMENT_FAILURE = 'MODAL_CREATE_MRK_DOCUMENT/EDIT_MRK_DOCUMENT_FAILURE';

export const editDocument = documentId => {
  return async (dispatch, getState, api) => {
    dispatch({ type: EDIT_MRK_DOCUMENT_REQUEST });
    try {
      const {
        auth: { token }
      } = getState();
      const result = await api.MrkClientServiceClient.getMrkDocumentData(
        token,
        documentId
      );
      dispatch({
        type: EDIT_MRK_DOCUMENT_SUCCESS,
        payload: result
      });
    } catch (error) {
      NotificationError(error, 'getMrkDocumentData');
      dispatch({ type: EDIT_MRK_DOCUMENT_FAILURE });
    }
  };
};

export const editWizardDocument = documentId => {
  return async (dispatch) => {
    dispatch(actions.showModal('MODAL_CREATE_WIZARD_DOCUMENT', {
      documentId: documentId
    }));
  };
};

const sendDocument = async (token, api, signProviderType, publicKey = null, docId, atts) => {
  const infoForSing = await api.MrkClientServiceClient.getDocumentInfoForSing(
    token,
    docId
  );
  let signature = null;
  let attachmentSignature = null;
  if (signProviderType === SignProviderType.UA_SIGN && publicKey !== null) {
    signature = await SignDataIIT(infoForSing, false);
    const signAtts = async (atts) => {
      const data = {};
      for (const item of atts) {
        const cms = await signAttachmentIIT(item);
        if (cms !== null) data[item.id] = cms;
      }
      return data;
    };
    attachmentSignature = await signAtts(atts);
  }
  if(signProviderType === SignProviderType.KAZ_SIGN){
    const s = await signDataNcaLayer(btoa(unescape(encodeURIComponent(infoForSing))));
    signature = s.responseObject;
  }
  return await api.MrkClientServiceClient.sendDocument(token, docId, signature, attachmentSignature, publicKey);
};

export const CREATE_OR_UPDATE_MRK_DOCUMENT_REQUEST = 'MODAL_CREATE_MRK_DOCUMENT/CREATE_OR_UPDATE_MRK_DOCUMENT_REQUEST';
export const CREATE_OR_UPDATE_MRK_DOCUMENT_SUCCESS = 'MODAL_CREATE_MRK_DOCUMENT/CREATE_OR_UPDATE_MRK_DOCUMENT_SUCCESS';
export const CREATE_OR_UPDATE_MRK_DOCUMENT_FAILURE = 'MODAL_CREATE_MRK_DOCUMENT/CREATE_OR_UPDATE_MRK_DOCUMENT_FAILURE';

export const createOrUpdateMrkDocument = (data, send = false, certificate = null, requiredSign = false) => {
  return async (dispatch, getState, api) => {
    dispatch({ type: CREATE_OR_UPDATE_MRK_DOCUMENT_REQUEST });
    try {
      const {
        auth: { token },
        settings: { SIGN_PROVIDER_TYPE }
      } = getState();
      const result = await api.MrkClientServiceClient.createOrUpdateMrkDocument(
        token,
        data
      );
      if (send) {
        let resultSend = null;
        if (requiredSign && ((certificate !== null && SIGN_PROVIDER_TYPE === SignProviderType.UA_SIGN) || SIGN_PROVIDER_TYPE === SignProviderType.KAZ_SIGN)) {
          resultSend = await sendDocument(token, api, SIGN_PROVIDER_TYPE, get(certificate, 'publicKey', null), result.document.id, result.atts);
        }  else {
          resultSend = await api.MrkClientServiceClient.sendDocument(token, result.document.id, null, null, null);
        }
        dispatch({
          type: CREATE_OR_UPDATE_MRK_DOCUMENT_SUCCESS,
          payload: resultSend
        });
        notification.success({
          key: 'sendDocument',
          message: I18n.t('notification.sendDocument_title')
        });
      } else {
        dispatch({
          type: CREATE_OR_UPDATE_MRK_DOCUMENT_SUCCESS,
          payload: result
        });
      }
      dispatch(getMrkDocuments()); 
      dispatch(actions.hideModal('MODAL_CREATE_MRK_DOCUMENT'));
    } catch (error) {
      NotificationError(error, 'createOrUpdateMrkDocument');
      dispatch({ type: CREATE_OR_UPDATE_MRK_DOCUMENT_FAILURE });
    }
  };
};

export const INIT_STATE = 'MODAL_CREATE_MRK_DOCUMENT/INIT_STATE';
export const initState = () => {
  return dispatch => {
    dispatch({
      type: INIT_STATE
    });
  };
};
